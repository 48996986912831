'use client';
import styled from 'styled-components';

export const LinkContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(4, 0, 8, 0)};
  text-align: center;
`;

export const FormWrapper = styled.div`
  padding: ${props => props.theme.spacing(14, 0)};
  background-color: ${({ theme }) => theme.colors.background.base};
`;
