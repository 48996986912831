'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { Icon } from '@ui/icons';
import { useReporter } from '../../reporter';
import {
  userClickEditBakedRecipe,
  userClickEditFreshRecipe,
} from './events.js';
import {
  Container,
  InfoLine,
  IconContainer,
  Anchor,
  Title,
  TitleContainer,
  DoubleInfoLinkContainer,
  InfoListContainer,
  InfoLineBold,
  InfoContainer,
  HrTag,
} from './styles.js';

export const RecipeNavLink = ({
  title,
  freshHref,
  bakedHref,
  onInfoClick,
  recipes,
}) => {
  const reporter = useReporter();
  const formatProduct = () => {
    const entries = Object.entries(recipes);
    return entries
      .filter(item => item[1].length !== 0)
      .map((prepType, index) => {
        return (
          <Anchor
            key={index}
            href={prepType[0] === 'dry' ? bakedHref : freshHref}
            onClick={() =>
              prepType[0] === 'dry'
                ? reporter.tag(userClickEditBakedRecipe())
                : reporter.tag(userClickEditFreshRecipe())
            }
          >
            <HrTag />
            <DoubleInfoLinkContainer onClick={onInfoClick}>
              <InfoContainer>
                <InfoLine>
                  {prepType[0] === 'dry' ? 'Baked' : capitalize(prepType[0])}
                </InfoLine>
                <InfoLineBold>
                  {capitalize(prepType[1].join(', '))}
                </InfoLineBold>
              </InfoContainer>
              <IconContainer>
                <Icon
                  iconName="chevron-right"
                  alt="chevron right icon"
                  color="text.secondary"
                  size="22"
                  id="recipe_nav_right"
                ></Icon>
              </IconContainer>
            </DoubleInfoLinkContainer>
          </Anchor>
        );
      });
  };
  return (
    <Container>
      <InfoListContainer>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        {formatProduct()}
        <HrTag />
        <Anchor href="/ingredients" target="_blank">
          <DoubleInfoLinkContainer>
            <InfoContainer>
              <InfoLineBold>View product information</InfoLineBold>
            </InfoContainer>
            <IconContainer>
              <Icon
                iconName="chevron-right"
                alt="chevron right icon"
                color="text.secondary"
                size="22"
                id="recipe_nav_right"
              ></Icon>
            </IconContainer>
          </DoubleInfoLinkContainer>
        </Anchor>
      </InfoListContainer>
    </Container>
  );
};

RecipeNavLink.displayName = 'RecipeNavLink';

RecipeNavLink.propTypes = {
  bakedHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  freshHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  title: PropTypes.string.isRequired,
  onInfoClick: PropTypes.func,
  recipes: PropTypes.shape({
    dry: PropTypes.array,
    fresh: PropTypes.array,
  }).isRequired,
};
