import { ApiResult } from '../../api';
import { TOAST_CLIENT_ERROR_MESSAGE } from '../../Toast';
import { TOAST_SERVER_MESSAGE } from '../constants';

export const handleSubscriptionAddonChanges = async (
  createAddon,
  addon,
  showToast,
) => {
  const result = await createAddon(addon);
  ApiResult.match(result, {
    success: () => {
      showToast({
        status: 'success',
        message: 'Update any time in the Extras section below.',
        headline: `${addon.productName} added!`,
      });
    },
    error: {
      client: error => {
        showToast({
          status: 'error',
          message: TOAST_CLIENT_ERROR_MESSAGE,
          headline: 'Error Message',
        });
      },
      server: () => {
        showToast({
          status: 'error',
          message: TOAST_SERVER_MESSAGE,
          headline: 'Error Message',
        });
      },
    },
  });
};
