'use client';
import styled from 'styled-components';

export const StyledEditAddressContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background.base};
  padding: ${props => props.theme.spacing(0, 6, 24)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(0, 6, 43)};
  }
`;

export const Content = styled.div`
  max-width: 512px;
  margin: auto;
`;

export const HeadingContainer = styled.div`
  margin: ${props => props.theme.spacing(14, 0, 8)};
`;
