'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-radius: 32px;
  background-color: ${props => props.theme.colors.background.lightest};
  padding: ${props => props.theme.spacing(6)};
  padding-bottom: ${props => props.theme.spacing(0)};
  /* Compensate for 10px of extra spacing in Icon */
  padding-right: calc(${props => props.theme.spacing(6)} - 10px);
  width: 100%;
  position: relative;
  &:hover {
    box-shadow: 0px 10px 16px rgba(94, 88, 78, 0.1);
  }
`;

export const InfoListContainer = styled.div`
  flex: 1;
`;

export const IconContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  svg {
    max-height: 25px;
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const HrTag = styled.hr`
  border: none;
  height: 1px;
  background-color: ${({ theme }) =>
    theme.colors.withAlpha('border.fur', 0.25)};
`;

export const Anchor = styled(Link)`
  display: block;
  ${props => props.theme.typography.heading4}
  color: ${props => props.theme.colors.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};

  &:first-of-type {
    ${HrTag} {
      display: none;
    }
  }
`;

export const Title = styled.div`
  ${props => props.theme.typography.heading4}
  color: ${props => props.theme.colors.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const InfoLine = styled.p`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.text.secondary};
`;

export const InfoLineBold = styled.p`
  ${props => props.theme.typography.body}
  font-weight: bold;
  color: ${props => props.theme.colors.text.secondary};
`;

export const DoubleInfoLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.spacing(1, 0, 4)};

  &:last-of-type {
    margin-top: ${props => props.theme.spacing(1)};
  }
`;

export const InfoContainer = styled.div``;
