import {
  addonImageMap,
  ALL_EXTRAS_EVERY_OTHER,
  ALL_EXTRAS_NEXT_ORDER,
  SOME_EXTRAS_NEXT_ORDER,
} from './constants';

export const getAddonsConfig = ({
  latestOrderAddons,
  subscriptionAddons,
  isLockedIn,
}) => {
  const allAddonsInBox = latestOrderAddons.length === subscriptionAddons.length;
  const hasSubAddonsButNoneInNextBox =
    Boolean(subscriptionAddons.length) && !latestOrderAddons.length;
  const someExtrasEveryOther = subscriptionAddons.some(
    addon => addon.frequency === 2,
  );
  const everyExtraEveryOther =
    subscriptionAddons.length > 0 &&
    subscriptionAddons.every(addon => addon.frequency === 2);

  const addonsToShow = latestOrderAddons;

  let noteText;
  if (!latestOrderAddons.length && everyExtraEveryOther)
    noteText = ALL_EXTRAS_EVERY_OTHER;
  else if (!allAddonsInBox && someExtrasEveryOther && latestOrderAddons.length)
    noteText = SOME_EXTRAS_NEXT_ORDER;
  else if (hasSubAddonsButNoneInNextBox && isLockedIn)
    noteText = ALL_EXTRAS_NEXT_ORDER;

  return {
    addons: addonsToShow.filter(addon => addonImageMap[addon.sku]),
    noteText,
  };
};

export const getSubtext = ({
  hasFresh,
  hasBaked,
  cadence,
  totalBags,
  totalPacks,
}) => {
  if (hasFresh && hasBaked)
    return (
      cadence +
      ` Week Supply (${totalPacks} packs of fresh, ${totalBags} bags of baked)`
    );
  else if (hasFresh)
    return cadence + ` Week Supply (${totalPacks} packs of fresh)`;
  else if (hasBaked)
    return cadence + ` Week Supply (${totalBags} bags of baked)`;
};
