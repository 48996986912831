'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 32px;
  background: ${props => props.theme.colors.background.lightest};
  margin-top: ${props => props.theme.spacing(4)};
  width: 100%;
  position: relative;
  @media ${props => props.theme.laptop} {
    display: flex;
    flex-direction: row;
  }
`;

export const DateInfo = styled.div`
  padding: ${props => props.theme.spacing(8, 5, 0, 5)};
  text-align: center;
  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(10, 2)};
    max-width: 347px;
    width: 100%;
  }
`;

export const Contents = styled.div`
  padding: ${props => props.theme.spacing(7, 0, 8)};
  text-align: center;
  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(10, 0)};
    flex: 1;
    text-align: left;
  }
`;

export const ArrivalText = styled.p`
  ${props => props.theme.typography.heading4};
  margin: ${props => props.theme.spacing(4, 0, 2)};
`;

export const IncludedText = styled.p`
  ${props => props.theme.typography.heading4};
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const SubText = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.secondary};
  margin-bottom: ${props => props.theme.spacing(3)};
  padding: ${props => props.theme.spacing(0, 5, 0, 5)};
  @media ${props => props.theme.laptop} {
    padding: 0px;
  }
`;

export const ProductInfoAnchor = styled(Link)`
  color: ${props => props.theme.colors.hero.huckleberry};
`;

export const NoteText = styled(SubText)`
  padding: ${props => props.theme.spacing(3, 5, 2, 5)};
  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(3, 5, 2, 0)};
  }
`;

export const ChangeNotAllowedText = styled.button`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.status.warningDark};
  background: none;
  border: none;
  padding-bottom: ${props => props.theme.spacing(4)};
`;

export const ChangeNotAllowedTip = styled.div`
  text-align: center;
  max-width: 300px;
  a {
    color: white;
    text-decoration: underline;
  }
`;

export const TrackingLinkContainer = styled.div`
  margin: ${props => props.theme.spacing(5, 0, 5, 0)};
  @media ${props => props.theme.laptop} {
    margin: ${props => props.theme.spacing(5, 0, 0, 0)};
  }
`;

export const OrderDetailsButton = styled.button`
  ${props => props.theme.typography.button}
  color: ${props => props.theme.colors.hero.blueberry};
  background: none;
  border: none;
  padding: 0;
  margin-top: ${props => props.theme.spacing(5)};
`;

export const RecipeCount = styled.p`
  ${props => props.theme.typography.bodyBold};
  margin-bottom: ${props => props.theme.spacing(6)};
  margin-top: ${props => props.theme.spacing(3)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  @media ${props => props.theme.laptop} {
    justify-content: left;
    height: fit-content;
    margin-top: ${props => props.theme.spacing(4)};
    margin-bottom: ${props => props.theme.spacing(4)};
  }
`;

export const EditAnchor = styled(Link)`
  color: ${props => props.theme.colors.hero.huckleberry};
  margin-left: ${props => props.theme.spacing(2)};
`;

export const RecipeImageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  margin: ${props => props.theme.spacing(0, 8, 3)};
  row-gap: ${props => props.theme.spacing(8)};
  @media ${props => props.theme.laptop} {
    display: flex;
    flex-wrap: wrap;
    gap: ${props => props.theme.spacing(7)};
    justify-content: flex-start;
    margin: 0px;
  }
`;

export const RecipeImage = styled.span`
  position: relative;
  width: 100px;
  margin: auto;
  @media ${props => props.theme.laptop} {
    border-radius: 10px;
    margin: 0;
  }
`;
export const AddonCount = styled.span`
  ${props => props.theme.typography.heading6}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  position: absolute;
  z-index: 9;
  width: 1.5rem;
  height: 1.5rem;
  color: ${props => props.theme.colors.text.lightest};
  text-align: center;
  top: -${props => props.theme.spacing(2)};
  left: -${props => props.theme.spacing(2)};
  background-color: #4c5d4c;
  border-radius: 50%;
`;
export const NoAddonsContainer = styled.div`
  display: flex;
  position: relative;
  gap: 15px;
  margin: ${props => props.theme.spacing(0, 4, 0, 4)};
  flex-direction: column;
  align-items: center;
  @media ${props => props.theme.laptop} {
    margin: ${props => props.theme.spacing(0, 4, 0, 0)};
    flex-direction: row;
    align-items: initial;
  }
`;
export const CollageImage = styled.div`
  width: 280px;
  height: 180px;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(8)};

  @media ${({ theme }) => theme.laptop} {
    margin-bottom: 0;
  }
`;

export const NoAddonsText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 330px;
  gap: ${props => props.theme.spacing(4)};
  margin: ${props => props.theme.spacing(0, 4, 0, 0)};
  @media ${props => props.theme.laptop} {
    gap: ${props => props.theme.spacing(0)};
  }
`;
export const AddonCountWithImage = styled.div`
  overflow: hidden;
  border-radius: 16px;
  width: 100px;
  height: 104px;
`;
