'use client';
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Heading } from '@web/atoms';
import { Footer, LoadingView } from '@web/components';
import { useReporter } from '../../reporter';
import { FOOTER_CONTENT } from '../../testing/constants';
import {
  useProduct,
  useProductRecommendations,
  getVariantByRecipes,
  determinePlanType,
} from '../../products';
import { useExperiment, FREQUENCY_PAGE_UI_UPDATES } from '../../experiments';
import { useSubscription } from '../../subscriptions';
import { RedirectToLogin } from '../../router';
import { useCurrentPet } from '../useCurrentPet';
import { ApiResult } from '../../api';
import { useToast, TOAST_CLIENT_ERROR_MESSAGE } from '../../Toast';
import { ERROR_MESSAGE, GF_PLAN_CHANGE_WARNING } from '../constants';
import {
  PRODUCT_TYPE_MIXED,
  PRODUCT_TYPE_FRESH,
  PRODUCT_TYPE_DRY,
  HALF_PORTION,
  FULL_PORTION,
} from '../../products/constants';
import { useSessionCustomer } from '../../customer';
import { FrequencyForm } from '../FrequencyForm';
import { AccountHeader } from '../AccountHeader';
import { viewBoxFrequencyPageEvent } from './events.js';
import {
  StyledFrequencyContainer,
  SubtitleContainer,
  Subtitle,
  Content,
  GFWarningText,
} from './styles.js';

export const FrequencyContainer = ({
  title,
  subtitle,
  submitButtonText,
  cancelButtonText,
  cancelBtnTarget,
  frequencyHref,
  frequencyLabel,
  links,
  logoUrl,
  logoSrText,
  chipList,
}) => {
  const reporter = useReporter();
  const { pet, isLoading: petIsLoading } = useCurrentPet();

  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();

  const {
    fresh,
    dry,
    mixed,
    half_fresh,
    isLoading: isPlanLoading,
  } = useProductRecommendations({
    petId: pet?.id,
  });

  const {
    subscription,
    isLoading: isSubscriptionLoading,
    updateSubscription,
  } = useSubscription(pet?.id);

  const { product, isLoading: isProductLoading } = useProduct(
    subscription?.sku,
  );

  const { value: frequencyPageUIUpdates } = useExperiment(
    FREQUENCY_PAGE_UI_UPDATES,
    'original',
  );

  const hasGFPlan = subscription?.sku.includes(pet?.id);

  const isLoading =
    isPlanLoading ||
    isSubscriptionLoading ||
    petIsLoading ||
    isProductLoading ||
    isCustomerLoading;
  const router = useRouter();
  const { showToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const mealPlanType = useMemo(() => {
    if (product) {
      const { product_type, portion_size } = product;
      if (product_type === PRODUCT_TYPE_MIXED) {
        return mixed.products;
      } else if (
        product_type === PRODUCT_TYPE_FRESH &&
        portion_size === FULL_PORTION
      ) {
        return fresh.products;
      } else if (
        product_type === PRODUCT_TYPE_FRESH &&
        portion_size === HALF_PORTION
      ) {
        return half_fresh.products;
      } else if (product_type === PRODUCT_TYPE_DRY) {
        return dry.products;
      }
    }
  }, [product, mixed, fresh, half_fresh, dry]);

  const choices = useMemo(
    () =>
      isLoading
        ? []
        : mealPlanType
            .map(p => {
              const recipes = {
                fresh: product?.variants[0].recipes.fresh,
                dry: product?.variants[0].recipes.dry,
              };

              const variant = getVariantByRecipes(p.variants, recipes);

              const planType = determinePlanType(recipes);

              const titles = {
                mixed: `$${variant.price_per_week} per week (${variant.tray_count} packs, ${variant.bag_count} bags)`,
                fresh: `$${variant.price_per_week} per week (${variant.tray_count} packs)`,
                baked: `$${variant.price_per_week} per week (${variant.bag_count} bags)`,
              };
              return {
                sku: variant.sku,
                weeks_of_food: p.weeks_of_food,
                title:
                  p.weeks_of_food > 1
                    ? `Every ${p.weeks_of_food} Weeks`
                    : `Every week`,
                body: titles[planType],
              };
            })
            .sort((a, b) => (a.weeks_of_food > b.weeks_of_food ? 1 : -1)),
    [isLoading, mealPlanType, product],
  );

  useEffect(() => {
    if (!isLoading) reporter.tag(viewBoxFrequencyPageEvent(choices.length));
  }, [choices, isLoading, reporter]);

  if (!isActive && !isCustomerLoading) {
    return <RedirectToLogin />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  const defaultValues = { sku: subscription.sku };

  const onSubmit = async selection => {
    setIsSubmitting(true);

    const result = await updateSubscription({
      frequency: selection.weeks_of_food,
      sku: selection.sku,
      pet_id: pet.id,
    });
    ApiResult.match(result, {
      success: () => {
        router.push(`/account/`);
        showToast({
          status: 'success',
          message: 'Your shipping frequency has been successfully updated.',
          headline: 'Success',
        });
      },
      error: {
        server: () => {
          showToast({
            status: 'error',
            message: ERROR_MESSAGE,
            headline: 'Error Message',
          });
        },
        client: error => {
          reporter.error(error.original);
          showToast({
            status: 'error',
            message: TOAST_CLIENT_ERROR_MESSAGE,
            headline: 'Error Message',
          });
        },
      },
    });
    ApiResult.ifError(result, () => setTimeout(() => setIsSubmitting(false)));
  };

  return (
    <StyledFrequencyContainer>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <Content>
        <Heading
          headingLevel="h1"
          typography="heading1"
          headingText={title}
          position="center"
        />
        <SubtitleContainer>
          <Subtitle>{subtitle}</Subtitle>
          {hasGFPlan && <GFWarningText>{GF_PLAN_CHANGE_WARNING}</GFWarningText>}
        </SubtitleContainer>
        <FrequencyForm
          choices={choices}
          defaultValues={defaultValues}
          frequencyHref={{
            pathname: frequencyHref,
            query: { pet_id: pet.id },
          }}
          cancelButtonText={cancelButtonText}
          cancelBtnTarget={cancelBtnTarget}
          submitButtonText={submitButtonText}
          frequencyLabel={frequencyLabel}
          chipList={chipList}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          showUIUpdates={frequencyPageUIUpdates === 'show_updated_frequency_ui'}
        />
      </Content>
      <Footer {...FOOTER_CONTENT} />
    </StyledFrequencyContainer>
  );
};

FrequencyContainer.displayName = 'FrequencyContainer';

FrequencyContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  cancelBtnTarget: PropTypes.string,
  frequencyHref: PropTypes.string.isRequired,
  frequencyLabel: PropTypes.string.isRequired,
  chipList: PropTypes.object.isRequired,
  links: PropTypes.array,
  logoUrl: PropTypes.string,
  logoSrText: PropTypes.string,
};
