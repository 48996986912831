'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinkButton } from '@ui/button';
import { SmolLink } from '@web/atoms';
import { useReporter } from '../../reporter';
import { changeDelivery } from './events';
import { DateCards } from './DateCards';
import {
  StyledQuickSelectForm,
  NextShippingContainer,
  LinkContainer,
  ButtonContainer,
  Subheading,
} from './styles.js';
import { schema } from './schema.js';

export const QuickSelectForm = ({
  choices,
  onSubmit,
  differentDateLabel,
  differentDateHref,
  submitButtonText,
  cancelButtonText,
  cancelButtonVariant,
  nextDeliveryLabel,
  nextDeliveryDate,
  frequencyHref,
  frequencyLabel,
  cancelHref,
  isSubmitting,
  linkVariant = 'hero.huckleberry',
}) => {
  const reporter = useReporter();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });
  const changeDateClick = () => {
    reporter.tag(changeDelivery());
  };
  return (
    <StyledQuickSelectForm>
      <NextShippingContainer>
        <Subheading>{nextDeliveryLabel}</Subheading>
        <Subheading subheading="true">{nextDeliveryDate}</Subheading>
      </NextShippingContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DateCards choices={choices} control={control} name="shippingDate" />
        <LinkContainer>
          <SmolLink href={differentDateHref} variant={linkVariant}>
            {differentDateLabel}
          </SmolLink>
        </LinkContainer>
        <ButtonContainer>
          <Button type="submit" disabled={isSubmitting}>
            {submitButtonText}
          </Button>
          <LinkButton href={cancelHref} variant={cancelButtonVariant}>
            {cancelButtonText}
          </LinkButton>
        </ButtonContainer>
        <LinkContainer>
          <SmolLink
            href={frequencyHref}
            onClick={changeDateClick}
            variant={linkVariant}
          >
            {frequencyLabel}
          </SmolLink>
        </LinkContainer>
      </form>
    </StyledQuickSelectForm>
  );
};
QuickSelectForm.displayName = 'QuickSelectForm';

QuickSelectForm.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  differentDateLabel: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  cancelButtonVariant: PropTypes.string,
  nextDeliveryLabel: PropTypes.string.isRequired,
  nextDeliveryDate: PropTypes.string.isRequired,
  frequencyLabel: PropTypes.string.isRequired,
  differentDateHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  frequencyHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  cancelHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  linkVariant: PropTypes.string,
};
