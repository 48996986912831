import { useEffect, useState } from 'react';
import {
  createMachine,
  state,
  state as final,
  state as initial,
  transition,
  reduce,
  guard,
  immediate,
} from 'robot3';
import { createUseMachine } from 'robot-hooks';
import { ACTION_TYPES, STATE } from './constants.js';
import {
  selectedBaked,
  isMixed,
  isFreshOrSummaryEdit,
  isBakedOrMixed,
  hasMealPrep,
} from './guard';

export const useMachine = createUseMachine(useEffect, useState);

const createInitialContext = ({
  step = STATE.initial,
  recipes = { fresh: null, baked: null },
  mealPrepType = 'fresh',
  pet = null,
  petId = null,
  returnPath = null,
  isFreshOnly = true,
} = {}) => ({
  pet,
  petId,
  mealPrepType,
  recipes,
  step,
  returnPath,
  isFreshOnly,
});

const hasPet = ctx => {
  return ctx.pet;
};

// `petId` becomes an actual pet UUID on success, otherwise, we use `true`
const hasRealPetId = ctx =>
  typeof ctx.petId === 'string' && ctx.petId !== 'true';
const hasSubscription = ctx => {
  // Has fresh recipes for fresh prep
  if (ctx.mealPrepType === 'fresh' && ctx.recipes.fresh != null) {
    return true;
  }

  // Has baked recipes for baked prep
  if (ctx.mealPrepType === 'dry' && ctx.recipes.baked != null) {
    return true;
  }

  // Has both recipes for mixed prep
  if (
    ctx.mealPrepType === 'mixed' &&
    ctx.recipes.fresh != null &&
    ctx.recipes.baked != null
  ) {
    return true;
  }

  return false;
};
const isStep = step => ctx => ctx.step === step;

export const addPupMachine = createMachine(
  STATE.initial,
  {
    [STATE.initial]: initial(
      immediate(
        STATE.summary,
        guard(isStep(STATE.summary)),
        guard(hasPet),
        guard(hasSubscription),
      ),
      immediate(
        STATE.delivery,
        guard(isStep(STATE.delivery)),
        guard(hasPet),
        guard(hasSubscription),
      ),
      immediate(
        STATE.payment,
        guard(isStep(STATE.payment)),
        guard(hasPet),
        guard(hasSubscription),
      ),
      immediate(
        STATE.success,
        guard(isStep(STATE.success)),
        guard(hasRealPetId),
      ),
      immediate(
        STATE.baked,
        guard(isStep(STATE.baked)),
        guard(hasPet),
        guard(hasMealPrep),
        guard(isBakedOrMixed),
      ),
      immediate(STATE.quiz),
    ),
    [STATE.quiz]: state(
      transition(
        ACTION_TYPES.toSummary,
        STATE.summary,
        guard(hasPet),
        guard(hasSubscription),
        reduce((ctx, { payload }) => ({
          ...ctx,
          step: STATE.summary,
        })),
      ),
      transition(
        ACTION_TYPES.submitPupForm,
        STATE.mealprep,
        reduce((ctx, { payload }) => ({
          ...ctx,
          pet: payload.pet,
          step: STATE.mealprep,
        })),
      ),
    ),
    [STATE.mealprep]: state(
      transition(
        ACTION_TYPES.toSummary,
        STATE.summary,
        guard(hasPet),
        guard(hasSubscription),
        reduce((ctx, { payload }) => ({
          ...ctx,
          step: STATE.summary,
        })),
      ),
      transition(
        ACTION_TYPES.selectedMealPrep,
        STATE.baked,
        guard(hasPet),
        guard(selectedBaked),
        reduce((ctx, { payload }) => ({
          ...ctx,
          mealPrepType: payload.mealPrepType,
          step: STATE.baked,
        })),
      ),
      transition(
        ACTION_TYPES.selectedMealPrep,
        STATE.fresh,
        guard(hasPet),
        reduce((ctx, { payload }) => ({
          ...ctx,
          mealPrepType: payload.mealPrepType,
          step: STATE.fresh,
        })),
      ),
    ),
    [STATE.fresh]: state(
      transition(
        ACTION_TYPES.selectedFreshRecipes,
        STATE.summary,
        guard(isFreshOrSummaryEdit),
        guard(hasMealPrep),
        reduce((ctx, { payload }) => ({
          ...ctx,
          step: STATE.summary,
          recipes: { fresh: payload.recipes, baked: [] },
        })),
      ),
      transition(
        ACTION_TYPES.toAddPupQuiz,
        STATE.quiz,
        reduce((ctx, { payload }) => ({
          ...ctx,
          mealPrepType: '',
          step: STATE.quiz,
        })),
      ),
      transition(
        ACTION_TYPES.selectedFreshRecipes,
        STATE.baked,
        guard(isMixed),
        reduce((ctx, { payload }) => ({
          ...ctx,
          step: STATE.baked,
          recipes: { ...ctx.recipes, fresh: payload.recipes },
        })),
      ),
    ),
    [STATE.baked]: state(
      transition(
        ACTION_TYPES.selectedBakedRecipes,
        STATE.summary,
        guard(hasMealPrep),
        guard(isMixed),
        reduce((ctx, { payload }) => ({
          ...ctx,
          step: STATE.summary,
          recipes: { ...ctx.recipes, baked: payload.recipes },
        })),
      ),
      transition(
        ACTION_TYPES.selectedBakedRecipes,
        STATE.summary,
        guard(hasMealPrep),
        reduce((ctx, { payload }) => ({
          ...ctx,
          step: STATE.summary,
          recipes: { fresh: [], baked: payload.recipes },
        })),
      ),
    ),
    [STATE.summary]: state(
      transition(
        ACTION_TYPES.toMealPrep,
        STATE.mealprep,
        reduce(({ ...ctx }, event) => ({
          ...ctx,
          step: STATE.mealprep,
          lastStep: STATE.summary,
        })),
      ),
      transition(
        ACTION_TYPES.selectedMealPrep,
        STATE.fresh,
        reduce(({ ...ctx }, event) => ({
          ...ctx,
          step: STATE.fresh,
          lastStep: STATE.summary,
        })),
      ),
      transition(
        ACTION_TYPES.toEditBaked,
        STATE.baked,
        reduce(({ ...ctx }, event) => ({
          ...ctx,
          step: STATE.baked,
          lastStep: STATE.summary,
        })),
      ),
      transition(
        ACTION_TYPES.toPayment,
        STATE.payment,
        reduce(({ ...ctx }, event) => ({
          ...ctx,
          step: STATE.payment,
          lastStep: STATE.summary,
        })),
      ),
      transition(
        ACTION_TYPES.toDelivery,
        STATE.delivery,
        reduce(({ ...ctx }, event) => ({
          ...ctx,
          step: STATE.delivery,
          lastStep: STATE.summary,
        })),
      ),
      transition(
        ACTION_TYPES.toSuccess,
        STATE.success,
        reduce(({ ...ctx }, event) => ({
          ...ctx,
          step: STATE.success,
          petId: event.payload.petId,
        })),
      ),
      transition(
        ACTION_TYPES.toAddPupQuiz,
        STATE.quiz,
        reduce(({ ...ctx }, event) => ({
          ...ctx,
          step: STATE.quiz,
          lastStep: STATE.summary,
        })),
      ),
      transition(
        ACTION_TYPES.toInitial,
        STATE.initial,
        reduce(({ ...ctx }) => ({
          ...ctx,
          step: STATE.initial,
          cancelled: true,
        })),
      ),
    ),
    [STATE.delivery]: state(
      transition(
        ACTION_TYPES.toSummary,
        STATE.summary,
        guard(hasPet),
        guard(hasSubscription),
        reduce((ctx, event) => ({
          ...ctx,
          step: STATE.summary,
        })),
      ),
    ),
    [STATE.payment]: state(
      transition(
        ACTION_TYPES.toSummary,
        STATE.summary,
        guard(hasPet),
        guard(hasSubscription),
        reduce((ctx, event) => ({
          ...ctx,
          step: STATE.summary,
        })),
      ),
    ),
    [STATE.success]: final(),
  },
  createInitialContext,
);
