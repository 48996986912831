'use client';
import styled from 'styled-components';

export const StyledDogSelection = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 330px;
  margin-top: ${props => props.theme.spacing(6)};
  @media ${props => props.theme.laptop} {
    margin-top: 0;
  }
`;

export const Label = styled.label`
  ${props => props.theme.typography.subheading3};
  display: block;
  margin-bottom: ${props => props.theme.spacing(3)};
  @media ${props => props.theme.laptop} {
    ${props => props.theme.typography.subheading2};
  }
`;

export const LinkContainer = styled.div`
  margin-left: ${props => props.theme.spacing(3)};
  svg {
    position: relative;
    left: 0;
    bottom: -3px;
  }
`;
