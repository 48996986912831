import React from 'react';
import PropTypes from 'prop-types';
import { StyledWelcomeBack, Headline, Subheadline } from './styles.js';

export const WelcomeBack = ({ headline, subheadline }) => {
  return (
    <StyledWelcomeBack>
      <Headline>{headline}</Headline>
      <Subheadline>{subheadline}</Subheadline>
    </StyledWelcomeBack>
  );
};
WelcomeBack.displayName = 'WelcomeBack';
WelcomeBack.propTypes = {
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.string.isRequired,
};
