'use client';
import styled from 'styled-components';

export const StyledBoxShippingSoonContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.base};
  min-height: 100vh;
  padding: ${props => props.theme.spacing(12, 6, 8)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(14, 6, 24)};
  }
`;

export const Container = styled.div`
  max-width: 688px;
  margin: 0 auto;
`;
