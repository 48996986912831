'use client';
import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spacing(8, 5, 6, 5)};
  position: relative;
  border: 2px solid
    ${props =>
      props.active ? props.theme.colors.status.warningDark : 'transparent'};
  background-color: ${props => props.theme.colors.background.lightest};
  border-radius: ${props => props.theme.spacing(8)};
  min-width: fit-content;
`;

export const Label = styled.label`
  ${props => props.theme.typography.heading5};
  color: ${props => props.theme.colors.text.primary};
  &::before {
    cursor: pointer;
    position: absolute;
    content: ' ';
    height: 100%;
    width: 100%;
    top: -0.75px;
    left: -0.75px;
    border-radius: ${props => props.theme.spacing(8)};
  }
  input:focus + &::before,
  &:hover:before {
    box-shadow: 0px 10px 16px rgba(94, 88, 78, 0.1);
  }
`;
export const Input = styled.input`
  ${props => props.theme.visuallyHidden}
`;

export const Info = styled.div`
  text-align: ${props => (props.$textAlign ? props.$textAlign : 'center')};
`;

export const Body = styled.p`
  color: ${props => props.theme.colors.get(props.$fontColor)};
  ${props => props.theme.typography.body};
  margin-top: ${props => props.theme.spacing(2)};
`;

export const ImageContainer = styled.div`
  margin: ${props => props.theme.spacing(0, 6, 2, 0)};
  display: flex;
  align-items: center;
`;

export const Footer = styled.p`
  color: ${props => props.theme.colors.get(props.$footerColor)};
  ${props => props.theme.typography.body};
  margin-top: ${props => props.theme.spacing(2)};
`;
