'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-radius: 32px;
  background-color: ${props => props.theme.colors.background.lightest};
  padding: ${props => props.theme.spacing(6)};
  /* Compensate for 10px of extra spacing in Icon */
  padding-right: calc(${props => props.theme.spacing(6)} - 10px);
  width: 100%;
  min-height: 138px;
  align-items: center;
  position: relative;
  &:hover {
    box-shadow: 0px 10px 16px rgba(94, 88, 78, 0.1);
  }
`;

export const InfoListContainer = styled.div`
  flex: 1;
`;

export const IconContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  svg {
    max-height: 25px;
  }
`;

export const AnchorContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const Anchor = styled(Link)`
  ${props => props.theme.typography.heading4}
  color: ${props => props.theme.colors.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};
  &::before {
    cursor: pointer;
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
export const Action = styled.div`
  ${props => props.theme.typography.heading4}
  color: ${props => props.theme.colors.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};
  &::before {
    cursor: pointer;
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const InfoLine = styled.p`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.text.secondary};
`;
