'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import { Heading } from '@web/atoms';
import { useReferralRef, DASHBOARD_ZONE } from '../../referral';
import ReferImage from '../../images/account/Box.png';
import {
  StyledReferAFriendContainer,
  ContentWrapper,
  ButtonWrapper,
  Content,
  ReferText,
  ReferHeading,
} from './styles';

export const ReferAFriend = ({ discountAmount }) => {
  const rafRef = useReferralRef(DASHBOARD_ZONE);
  return (
    <>
      <Heading
        headingLevel="h3"
        typography={{ desktop: 'heading2', mobile: 'heading1' }}
        headingText={`Refer a friend, earn $${discountAmount}`}
      />
      <StyledReferAFriendContainer>
        <ContentWrapper>
          <ResponsiveImage
            {...{ src: ReferImage, alt: 'ollie box', width: 131, height: 110 }}
          />
          <Content>
            <ReferHeading>Refer & Earn ${discountAmount}</ReferHeading>
            <ReferText>
              Refer your friends to Ollie! Invite a friend to try Ollie with a
              special discount and get ${discountAmount} off your next box.
            </ReferText>
          </Content>
        </ContentWrapper>
        <ButtonWrapper>
          <Button
            ref={rafRef}
            variant="sweetPotato"
            verticalPadding={4}
            horizontalPadding={22}
          >
            Refer a friend
          </Button>
        </ButtonWrapper>
      </StyledReferAFriendContainer>
    </>
  );
};

ReferAFriend.displayName = 'ReferAFriend';

ReferAFriend.propTypes = {
  discountAmount: PropTypes.number.isRequired,
};
