import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { LinkButton } from '@ui/button';
import { PupAvatar, BrightbackContainer } from '@web/components';
import {
  ButtonContainer,
  Container,
  Content,
  Text,
  HeaderText,
} from './styles.js';

export const DogSubscriptionTile = ({
  petId,
  petName,
  petBreedsNames,
  reactivateUrlTarget,
  reactivateButtonLabel,
  brightbackInfo,
  subscriptionInfo,
  hasGFPlan,
}) => {
  return (
    <Container>
      <PupAvatar
        breeds={petBreedsNames}
        height={120}
        width={120}
        fullBleed={true}
      />
      <Content>
        <HeaderText>{petName}</HeaderText>
        <Text typography="body" color="text.secondary">
          {subscriptionInfo.active
            ? `Member since ${format(
                parseISO(subscriptionInfo.createdOn),
                'MMMM yyyy',
              )}`
            : `Cancelled since ${format(
                parseISO(subscriptionInfo.modifiedOn),
                'MMMM yyyy',
              )}`}
        </Text>
      </Content>
      <ButtonContainer>
        {subscriptionInfo.active ? (
          <BrightbackContainer {...brightbackInfo} hasGFPlan={hasGFPlan} />
        ) : (
          <LinkButton
            fullWidth
            variant={'secondary'}
            href={{
              pathname: reactivateUrlTarget,
              query: {
                pet_id: petId,
              },
            }}
          >
            {reactivateButtonLabel}
          </LinkButton>
        )}
      </ButtonContainer>
    </Container>
  );
};

DogSubscriptionTile.displayName = 'DogSubscriptionTile';
DogSubscriptionTile.propTypes = {
  petId: PropTypes.string.isRequired,
  petName: PropTypes.string.isRequired,
  petBreedsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  reactivateButtonLabel: PropTypes.string.isRequired,
  reactivateUrlTarget: PropTypes.string.isRequired,
  hasGFPlan: PropTypes.bool.isRequired,
  brightbackInfo: PropTypes.shape({
    customerId: PropTypes.number.isRequired,
    customerEmail: PropTypes.string.isRequired,
    petSubscriptionId: PropTypes.string.isRequired,
    addOns: PropTypes.bool.isRequired,
    accountCreationDate: PropTypes.string.isRequired,
    mealPrepType: PropTypes.string.isRequired,
    planFrequency: PropTypes.number.isRequired,
    nextDeliveryDate: PropTypes.string.isRequired,
    lastOrderSubtotal: PropTypes.string.isRequired,
  }).isRequired,
  subscriptionInfo: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    createdOn: PropTypes.string.isRequired,
    modifiedOn: PropTypes.string.isRequired,
  }).isRequired,
};
