'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinkButton } from '@ui/button';
import { ShippingAddress, ShippingAddressSchema } from '@web/components';
import { Cell, Grid } from '@web/atoms';
import { ApiResult } from '../../api';
import { US_STATE_OPTIONS } from './constants';
import { ButtonContainer } from './styles';

export const EditAddressForm = ({
  labelAddress,
  labelApt,
  labelZipCode,
  labelCity,
  labelState,
  labelPhoneNumber,
  labelSMSUpdates,
  labelSMSTerms,
  labelButtonSave,
  labelButtonCancel,
  cancelUrl,
  onSubmit,
  defaultValues,
  isSubmitting,
}) => {
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(ShippingAddressSchema),
    mode: 'onBlur',
  });
  const handleFormSubmit = async data => {
    const result = await onSubmit(data);
    ApiResult.match(result, {
      error: {
        address: ({ fields, nonFieldErrors }) => {
          //some address errors are handled by shippo and return as a generic non-field error
          if (nonFieldErrors) {
            methods.setError(
              'address',
              { type: 'validate', message: 'Address could not be found.' },
              { shouldFocus: true },
            );
          } else {
            //field errors handled by us will be mapped accordingly
            fields.forEach(({ name, message }) =>
              methods.setError(
                name,
                { type: 'validate', message },
                { shouldFocus: true },
              ),
            );
          }
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <Grid gap={{ row: 2, column: 2 }} columns={{ tablet: 2, mobile: 2 }}>
          <Cell columns={{ tablet: 2, mobile: 2 }}>
            <ShippingAddress
              labelAddress={labelAddress}
              labelApt={labelApt}
              labelZipCode={labelZipCode}
              labelCity={labelCity}
              labelState={labelState}
              stateOptions={US_STATE_OPTIONS}
              labelPhoneNumber={labelPhoneNumber}
              labelSMSUpdates={labelSMSUpdates}
              labelSMSTerms={labelSMSTerms}
            />
          </Cell>
          <Cell columns={{ tablet: 2, mobile: 2 }}>
            <ButtonContainer>
              <Button disabled={isSubmitting} type="submit">
                {labelButtonSave}
              </Button>
              <LinkButton href={cancelUrl} variant="secondary">
                {labelButtonCancel}
              </LinkButton>
            </ButtonContainer>
          </Cell>
        </Grid>
      </form>
    </FormProvider>
  );
};
EditAddressForm.displayName = 'EditAddressForm';
EditAddressForm.propTypes = {
  labelAddress: PropTypes.string.isRequired,
  labelApt: PropTypes.string.isRequired,
  labelZipCode: PropTypes.string.isRequired,
  labelCity: PropTypes.string.isRequired,
  labelState: PropTypes.string.isRequired,
  labelPhoneNumber: PropTypes.string.isRequired,
  labelSMSUpdates: PropTypes.string.isRequired,
  labelSMSTerms: PropTypes.string.isRequired,
  labelButtonSave: PropTypes.string.isRequired,
  labelButtonCancel: PropTypes.string.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
