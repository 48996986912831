'use client';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: ${props => props.theme.spacing(4)};
  background-color: ${props => props.theme.colors.background.bone};
  border-radius: ${props => props.theme.spacing(8)};
  padding: ${props => props.theme.spacing(6)};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${props => props.theme.tablet} {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(4)};
  }
`;

export const Content = styled.div`
  max-width: none;
  width: 100%;

  @media ${({ theme }) => theme.tablet} {
    margin: 0 auto;
    max-width: 250px;
  }

  @media ${({ theme }) => theme.tabletL} {
    max-width: 300px;
  }

  @media ${({ theme }) => theme.laptopL} {
    max-width: 500px;
  }
`;

export const HeroTitle = styled.h4`
  ${props => props.theme.typography.heading3}
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const BodyText = styled.p`
  margin: ${({ theme }) => theme.spacing(4, 0)};
  ${({ theme }) => theme.typography.body}
`;

// Used to set image size and maintain aspect ratio.
export const ImageWrapper = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
  aspect-ratio: 1 / 0.83;

  @media ${({ theme }) => theme.tablet} {
    max-width: 174px;
    width: 100%;
  }
`;

export const AppStoreAnchor = styled.a``;

export const AppStoreButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ flexDirection }) =>
    flexDirection === 'column' ? 'space-between' : 'space-around'};
  align-items: center;
  margin: 0 auto;
  max-width: 206px;
  width: 100%;
  flex: 1;

  @media ${({ theme }) => theme.laptop} {
    flex-direction: column;
    max-width: ${({ flexDirection }) =>
      flexDirection === 'column' ? 206 : 450}px;
  }

  @media ${({ theme }) => theme.laptopL} {
    flex-direction: ${({ flexDirection }) => flexDirection};
  }
  ${AppStoreAnchor} {
    &:first-of-type {
      margin-bottom: 1rem;

      @media ${({ theme }) => theme.laptopL} {
        margin-right: ${({ flexDirection }) =>
          flexDirection === 'column' ? 0 : 1}rem;
        margin-bottom: ${({ flexDirection }) =>
          flexDirection === 'column' ? 1 : 0}rem;
      }
    }
  }
`;
