import {
  productTypeReference,
  FRESH_PRODUCT_TYPES,
  PRODUCT_TYPE_FRESH,
  PRODUCT_TYPE_DRY,
  PRODUCT_TYPE_MIXED,
  PRODUCT_TYPE_HALF_FRESH,
} from '../../products/constants';
import { STATE } from './constants';

export const selectedBaked = (ctx, { payload }) =>
  payload.mealPrepType === productTypeReference[PRODUCT_TYPE_DRY];

export const hasRecipes = ctx => {
  switch (ctx.mealPrepType) {
    case productTypeReference[PRODUCT_TYPE_FRESH]:
    case productTypeReference[PRODUCT_TYPE_HALF_FRESH]:
      return !!ctx.recipes.fresh.length;
    case productTypeReference[PRODUCT_TYPE_DRY]:
      return !!ctx.recipes.baked.length;
    case productTypeReference[PRODUCT_TYPE_MIXED]:
      return !!ctx.recipes.fresh.length && !!ctx.recipes.mixed.length;
    default:
      return false;
  }
};

export const hasFreshInCart = (ctx, { payload: { recipes } }) =>
  !!recipes.fresh.length;
export const hasDryInCart = (ctx, { payload: { recipes } }) =>
  !!recipes.baked.length;
export const hasNoMealPrep = ctx => ctx.mealPrepType === '';
export const hasMealPrep = ctx => !!ctx.mealPrepType;

export const isFresh = ctx => FRESH_PRODUCT_TYPES.includes(ctx.mealPrepType);
export const isBaked = ctx =>
  ctx.mealPrepType === productTypeReference[PRODUCT_TYPE_DRY];
export const isMixed = ctx =>
  ctx.mealPrepType === productTypeReference[PRODUCT_TYPE_MIXED];
export const isBakedOrMixed = ctx => isBaked(ctx) || isMixed(ctx);
export const isFreshOrMixed = ctx => isFresh(ctx) || isMixed(ctx);
export const isFreshOrSummaryEdit = ctx =>
  isFresh(ctx) || ctx.lastStep === STATE.summary;
export const isFreshUrl = (ctx, { payload }) =>
  payload.pathname === STATE.fresh;
export const isBakedUrl = (ctx, { payload }) =>
  payload.pathname === STATE.baked;
