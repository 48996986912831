'use client';
import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
  padding: ${props => props.theme.spacing(14, 6)};
  max-width: 888px;
`;

export const HeadingContainer = styled.div`
  margin: ${props => props.theme.spacing(0, 0, 10, 0)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(4)};
  width: 100%;
  max-width: 512px;
  margin: ${props => props.theme.spacing(10)} auto;
`;
