export const passwordInfo = ['Password', '*************'];
const deliveryInfo = ['62 Pine Hill Rd', 'Southborough, MA 01772'];
const paymentInfo = ['Payment method', 'Visa ****1234'];

export const userAccountInformation = [
  {
    title: 'ACCOUNT INFO',
    href: '/edit-password',
    infoList: passwordInfo,
    buttonLabel: 'Edit',
  },
  {
    title: 'DELIVERY INFO',
    href: '/edit-shipping-address',
    infoList: deliveryInfo,
    buttonLabel: 'Edit',
  },
  {
    title: 'PAYMENT INFO',
    href: '/edit-payment',
    infoList: paymentInfo,
    buttonLabel: 'Edit',
  },
];
