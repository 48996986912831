'use client';
import styled from 'styled-components';

export const InfoCardContainer = styled.div`
  width: 90%;
  margin: ${({ theme }) => theme.spacing(1)} auto;
  @media ${({ theme }) => theme.tabletL} {
    margin: ${({ theme }) => theme.spacing(0, 6, 6, 6)};
  }
`;
