import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules/ResponsiveImage';
import warningIcon from '../../images/Icons/warning-icon.svg';
import checkCircle from './check-circle.png';
import {
  Container,
  InfoListContainer,
  InfoLine,
  IconContainer,
} from './styles.js';

export const PaymentInfoCard = ({ infoList, onInfoClick, showPayment }) => {
  return (
    <Container onClick={onInfoClick}>
      <InfoListContainer>
        {infoList.map(info => (
          <InfoLine key={info}>{info}</InfoLine>
        ))}
      </InfoListContainer>
      <IconContainer>
        {showPayment ? (
          <ResponsiveImage
            src={checkCircle.src}
            alt={'Payment confirmed'}
            width={24}
            height={24}
          />
        ) : (
          <ResponsiveImage
            src={warningIcon}
            alt={'No Payment Method added'}
            width={24}
            height={24}
          />
        )}
      </IconContainer>
    </Container>
  );
};

PaymentInfoCard.propTypes = {
  infoList: PropTypes.arrayOf(PropTypes.node).isRequired,
  onInfoClick: PropTypes.func,
  showPayment: PropTypes.bool,
};
