export const userViewsChangeDeliveryDatePage = ({
  boxState,
  boxDeliveryDate,
  selectionType,
}) => ({
  type: `Views Change Delivery Date Page`,
  data: {
    current_box_state: boxState ? 'locked' : 'unlocked',
    box_editing: boxState ? 'next' : 'current',
    box_delivery_date: boxDeliveryDate,
    selection_type: selectionType,
  },
});

export const userUpdatesDeliveryDate = ({
  boxEditing,
  originalDeliveryDate,
  newDeliveryDate,
  daysChanged,
  selectionType,
  changeType,
}) => ({
  type: `User Updates Delivery Date`,
  data: {
    box_editing: boxEditing,
    selection_type: selectionType,
    change_type: changeType,
    original_delivery_date: originalDeliveryDate,
    new_delivery_date: newDeliveryDate,
    days_changed: daysChanged,
  },
});

export const userClicksOnChangeFrequency = () => ({
  type: 'User Clicks Change Frequency',
});
