import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { PillText } from '@web/atoms';
import { useReporter } from '../../reporter';
import {
  userClicksOnAppleDownloadButton,
  userClicksOnGoogleDownloadButton,
} from './events';
import {
  Container,
  Content,
  HeroTitle,
  BodyText,
  ImageWrapper,
  AppStoreButtonsContainer,
  AppStoreAnchor,
} from './styles';

export const OllieAppBanner = ({
  title,
  bodyText,
  promoPillText,
  ollieAppImage,
  ollieAppImageAltText,
  appleAppStoreButtonImage,
  appleAppStoreHref,
  appleAppStoreAltText,
  googlePlayStoreButtonImage,
  googlePlayStoreHref,
  googlePlayStoreAltText,
  appStoreButtonsFlow = 'column',
}) => {
  const reporter = useReporter();
  return (
    <>
      <Container>
        <ImageWrapper>
          <ResponsiveImage {...ollieAppImage} alt={ollieAppImageAltText} />
        </ImageWrapper>
        <Content>
          <PillText text={promoPillText} variant={'blueberry'} />
          <HeroTitle>{title}</HeroTitle>
          <BodyText>{bodyText}</BodyText>
        </Content>
        <AppStoreButtonsContainer flexDirection={appStoreButtonsFlow}>
          <AppStoreAnchor
            onClick={() => reporter.tag(userClicksOnAppleDownloadButton())}
            href={appleAppStoreHref}
            target="_blank"
          >
            <ResponsiveImage
              {...appleAppStoreButtonImage}
              alt={appleAppStoreAltText}
            />
          </AppStoreAnchor>
          <AppStoreAnchor
            onClick={() => reporter.tag(userClicksOnGoogleDownloadButton())}
            href={googlePlayStoreHref}
            target="_blank"
          >
            <ResponsiveImage
              {...googlePlayStoreButtonImage}
              alt={googlePlayStoreAltText}
            />
          </AppStoreAnchor>
        </AppStoreButtonsContainer>
      </Container>
    </>
  );
};

OllieAppBanner.displayName = 'OllieAppBanner';
OllieAppBanner.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.node.isRequired,
  promoPillText: PropTypes.string.isRequired,
  ollieAppImage: PropTypes.object.isRequired,
  ollieAppImageAltText: PropTypes.string.isRequired,
  appleAppStoreButtonImage: PropTypes.object.isRequired,
  appleAppStoreHref: PropTypes.string.isRequired,
  appleAppStoreAltText: PropTypes.string.isRequired,
  googlePlayStoreButtonImage: PropTypes.object.isRequired,
  googlePlayStoreHref: PropTypes.string.isRequired,
  googlePlayStoreAltText: PropTypes.string.isRequired,
  appStoreButtonsFlow: PropTypes.string,
};
