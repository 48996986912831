'use client';
import { parse } from 'url';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { findLast } from 'lodash';
import { LoadingView } from '@web/components';
import { translateContentToProps } from '../translation';
import { useSessionCustomer } from '../../customer';
import { RedirectToLogin, Redirect } from '../../router';
import { useSessionStorage } from '../../hooks';
import { PrefixedReporterProvider } from '../../reporter';
import { ADD_PET_FLOW_KEY } from '../../testing/account';
import { useProductRecommendations } from '../../products';
import { STATE, stepToAction, ACTIONS } from './constants';
import { components } from './components';
import { useMachine, addPupMachine } from './state';

const AddPupFlow = ({ content, steps }) => {
  const { isActive, isLoading: isCustomerLoading } = useSessionCustomer();
  const router = useRouter();
  const {
    slug: [slug],
  } = router.query;
  const [addPetFlow, setPetFlow] = useSessionStorage(ADD_PET_FLOW_KEY);
  const [state, send] = useMachine(addPupMachine, {
    ...addPetFlow,
    step: slug,
  });

  const lastComponentRef = useRef({ component: LoadingView, props: {} });

  const {
    fresh,
    dry,
    mixed,
    half_fresh,
    isLoading: isProductsLoading,
  } = useProductRecommendations({
    activity: state.context.pet?.activity,
    allergies: state.context.pet?.allergies?.map(({ value }) => value),
    neutered: state.context.pet?.spayedStatus === 'yes',
    weight: state.context.pet?.idealWeight
      ? state.context.pet?.idealWeight
      : state.context.pet?.weight,
  });

  const cmsProps = translateContentToProps(content);

  useEffect(() => {
    if (state.name !== STATE.success) {
      setPetFlow(state.context, { skipRender: true });
    }
  }, [state.name, state.context, setPetFlow]);

  useEffect(() => {
    if (slug !== state.name && state.name !== STATE.initial) {
      router.push({
        pathname: `${state.name}`,
      });
    }
  }, [slug, state.name, router]);

  useEffect(() => {
    const handler = url => {
      const parsedUrl = parse(url);
      if (parsedUrl.query?.includes('cancelled=true')) {
        return null;
      }
      const step = findLast(parsedUrl.pathname.split('/'), e => !!e);
      if (parsedUrl.query?.includes('edit=true')) {
        send(ACTIONS.toEditBaked());
      } else {
        const action = stepToAction[step];
        action && send(action());
      }
    };
    router.events.on('routeChangeStart', handler);
    return () => router.events.off('routeChangeStart', handler);
  }, [router.events, send]);

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isProductsLoading || isCustomerLoading || state.name === STATE.initial) {
    return <LoadingView />;
  }

  let Component = components[state.name];
  const petName = state.context.pet?.name;
  let props = {
    labels: steps[state.name],
    petForm: state.context.pet,
    productTypes: { fresh, dry, mixed, half_fresh },
    selectedPlanType: state.context.mealPrepType,
    selectedFreshRecipes: state.context.recipes.fresh,
    selectedBakedRecipes: state.context.recipes.baked,
    petId: state.context.petId,
    lastStep: state.context.lastStep,
    setPetFlow,
    petName,
    send,
    ...cmsProps,
  };

  if ((slug !== state.name || !Component) && state.name !== STATE.initial) {
    Component = lastComponentRef.current.component;
    props = lastComponentRef.current.props;
  } else lastComponentRef.current = { component: Component, props };

  return <Component {...props} />;
};

AddPupFlow.displayName = 'AddPupFlow';

AddPupFlow.propTypes = {
  content: PropTypes.object.isRequired,
  steps: PropTypes.shape({
    quiz: PropTypes.object.isRequired,
    mealprep: PropTypes.object.isRequired,
    fresh: PropTypes.object.isRequired,
    baked: PropTypes.object.isRequired,
    delivery: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
    success: PropTypes.object.isRequired,
    summary: PropTypes.object.isRequired,
  }).isRequired,
};

export const AddPupFlowContainer = ({ content, steps }) => {
  const router = useRouter();
  const { slug: slugs } = router.query;
  //TODO: @BruceRodrigues Without this the proptypes test will fail with undefined is not iterable error.
  //Don't know a good way to fix it at the moment
  const slug = slugs?.[0];
  if (!slug) {
    return <Redirect replace to={`/account/add-pup/${STATE.quiz}`} />;
  }

  return (
    <PrefixedReporterProvider prefix="MX Add Pup">
      <AddPupFlow content={content} steps={steps} />
    </PrefixedReporterProvider>
  );
};

AddPupFlowContainer.propTypes = {
  content: PropTypes.object.isRequired,
  steps: PropTypes.object.isRequired,
};
