import React from 'react';
import ollieAppImage from '../../images/account/ollie-app-image.png';
import googlePlayStoreButtonImage from '../../images/account/google-play-button.svg';
import appleAppStoreButtonImage from '../../images/account/apple-button.svg';
import ollieAppIncentiveImage from '../../images/CheckoutConfirmation/ollie-app-incentive.png';

const APP_STORE_PROPS = {
  appleAppStoreButtonImage: appleAppStoreButtonImage,
  appleAppStoreHref:
    'https://apps.apple.com/us/app/ollie-human-grade-dog-food/id1614301164',
  appleAppStoreAltText: 'Download on the Apple App Store',
  googlePlayStoreButtonImage: googlePlayStoreButtonImage,
  googlePlayStoreHref:
    'https://play.google.com/store/apps/details?id=com.ollie.mobile_app',
  googlePlayStoreAltText: 'GET IT ON Google Play',
};

export const DEFAULT_OLLIE_APP_BANNER_PROPS = {
  title: 'Introducing the all new Ollie app!',
  bodyText: 'Get box updates, manage your delivery date, and more.',
  promoPillText: 'NEW',
  ollieAppImage: ollieAppImage,
  ollieAppImageAltText: 'Ollie App image',
  ...APP_STORE_PROPS,
};

export const OLLIE_APP_BANNER_INCENTIVE_PROPS = {
  title: (
    <>
      Save <strong>$5 on your next box</strong> when you log in to our new
      mobile app.
    </>
  ),
  bodyText:
    'Download the app, log in and we’ll give you $5 off your next order.',
  promoPillText: 'NEW',
  ollieAppImage: ollieAppIncentiveImage,
  ollieAppImageAltText: 'Ollie App image',
  ...APP_STORE_PROPS,
  appStoreButtonsFlow: 'row',
};
