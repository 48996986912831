'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { useSubscription } from '../../subscriptions';
import { ACTIVE_STATUS } from '../AccountContainer/constants';
import { getMealPrepType } from './functions';
import { DogSubscriptionTile } from './components';

export const DogSubscriptionContainer = ({
  petId,
  petName,
  petBreedsNames,
  reactivateButtonLabel,
  reactivateUrlTarget,
}) => {
  const { subscription, isLoading: isSubscriptionLoading } =
    useSubscription(petId);
  const hasGFPlan = subscription?.sku.includes(petId);

  if (isSubscriptionLoading) return null;

  const brightbackInfo = {
    customerId: subscription.bc_customer_id,
    customerEmail: subscription.email,
    petSubscriptionId: subscription.id,
    addOns: subscription.addons.length > 0 ? true : false,
    accountCreationDate: subscription.created_on,
    planFrequency: subscription.frequency,
    lastOrderSubtotal: subscription.price,
    nextDeliveryDate: subscription.next_delivery_target,
    mealPrepType: getMealPrepType(subscription.sku),
  };
  const subscriptionInfo = {
    active: subscription.status === ACTIVE_STATUS,
    createdOn: subscription.created_on,
    modifiedOn: subscription.modified_on,
  };

  return (
    <DogSubscriptionTile
      petId={petId}
      petName={petName}
      petBreedsNames={petBreedsNames}
      reactivateUrlTarget={reactivateUrlTarget}
      reactivateButtonLabel={reactivateButtonLabel}
      brightbackInfo={brightbackInfo}
      subscriptionInfo={subscriptionInfo}
      hasGFPlan={hasGFPlan}
    />
  );
};

DogSubscriptionContainer.displayName = 'DogSubscriptionContainer';
DogSubscriptionContainer.propTypes = {
  petId: PropTypes.string.isRequired,
  petName: PropTypes.string.isRequired,
  petBreedsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  reactivateButtonLabel: PropTypes.string.isRequired,
  reactivateUrlTarget: PropTypes.string.isRequired,
};
