'use client';
import React from 'react';
import { useRouter } from 'next/router';
import { NarvarForm } from '../NarvarForm';
import { NARVAR_SCRIPT_SRC } from './constants';

export const NarvarContainer = () => {
  const router = useRouter();

  const renderNarvarTracker = () => {
    const { narvar } = window;
    const { tracking_numbers, carrier, order_number, service, t } =
      router.query;
    const narvarCodes = {
      columbus: 'columbus', // cdldelivers
      HD: 'fedex',
      ST: 'ontrac',
      uds: 'uds',
      ups: 'ups',
    };
    const carrierName = carrier ?? narvarCodes[service];

    if (narvar) {
      narvar('dynamicTrack', {
        localeLanguage: 'en',
        localeCountry: 'US',
        trackingNumbers: tracking_numbers ? tracking_numbers.split(',') : '',
        carrier: carrierName,
        orderNumbers: order_number ? order_number.split(',') : [],
        t: t ?? '',
      });
    }
  };

  return (
    <NarvarForm
      narvarScriptSrc={NARVAR_SCRIPT_SRC}
      renderNarvarTracker={renderNarvarTracker}
    />
  );
};

NarvarContainer.displayName = 'NarvarContainer';
NarvarContainer.propTypes = {};
