'use client';
import styled from 'styled-components';

export const StyledPlanSummary = styled.div`
  background: ${props => props.theme.colors.background.base};
  display: grid;
  gap: ${props => props.theme.spacing(6)};
  text-align: center;
`;

export const Subtitle = styled.p`
  ${props => props.theme.typography.body}
  padding: ${props => props.theme.spacing(10)};
  color: ${props => props.theme.colors.text.secondary};
`;

export const ButtonContainer = styled.div`
  margin: auto;
  max-width: 512px;
`;

export const BoxSummaryContainer = styled.div`
  margin-top: -${props => props.theme.spacing(8)};
`;

export const Container = styled.div`
  display: grid;
  justify-self: center;
  margin: ${props => props.theme.spacing(0, 3)};
  max-width: 700px;
`;

export const InfoContainer = styled.div`
  text-align: left;
  display: grid;
  gap: ${props => props.theme.spacing(6)};
`;

export const Disclaimer = styled.p`
  ${props => props.theme.typography.label}
  padding: ${props => props.theme.spacing(12, 0, 6)};
  color: ${props => props.theme.colors.text.secondary};
`;

export const NoPaymentMethodText = styled.p`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  ${({ theme }) => theme.fontFamily.base}
  color: ${({ theme }) => theme.colors.status.warningPrimary};
  padding: ${({ theme }) => theme.spacing(2, 0)};
  gap: ${props => props.theme.spacing(3)};

  @media (max-width: 650px) {
    align-items: flex-start;
  }
`;
