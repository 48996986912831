'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ui/button';
import { LoadingSpinner } from '@ui/loading-spinner';
import { ResponsiveImage } from '@web/molecules';
import { Heading, SmolLink, PillText } from '@web/atoms';
import { AddOnQuickView } from '@web/components';
import { useAddonModal } from '../../hooks';
import { useReporter } from '../../reporter';
import {
  getMergedAddonModals,
  getAvailabledAddons,
} from '../../addons/functions';
import {
  userUpdatesExtra,
  userClicksAddExtra,
  userClicksSeeAllExtras,
} from './events';
import {
  StyledQuickAddExtrasContainer,
  BadgeContainer,
  ExtrasContainer,
  Extra,
  ExtraInfoContainer,
  ExtraInfo,
  ProductName,
  Size,
  Price,
  ExtraImage,
  AddButtonContainer,
  SectionTitleContainer,
  LinkContainer,
  LoadingSpinnerContainer,
} from './styles';

export const QuickAddExtrasContainer = ({
  sectionTitle,
  seeExtrasLinkText,
  editExtrasLink,
  recommendedAddons,
  onQuickAddExtraClick,
  isSubscriptionAddonProductsValidating,
  subscribedAddons,
  addonModals,
  allTreats,
  allSupplements,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeSku, setActiveSku] = useState('');
  const topAddons = recommendedAddons.slice(0, 3);
  const reporter = useReporter();

  const availableAddons = getAvailabledAddons(
    addonModals,
    allTreats,
    allSupplements,
  );
  const previousAddons = subscribedAddons?.map(
    ({ sku, frequency, quantity }) => ({
      sku,
      frequency,
      quantity,
    }),
  );

  const handleOnQuickAddExtraClick = async extra => {
    setIsSubmitting(true);
    setActiveSku(extra.sku);
    await onQuickAddExtraClick(extra);
    reporter.tag(
      userUpdatesExtra(
        availableAddons.map(({ sku, title }) => ({
          sku,
          title,
        })),
        previousAddons,
        [...previousAddons, extra].map(({ sku, frequency, quantity }) => ({
          sku,
          frequency,
          quantity,
        })),
      ),
    );
    setActiveSku('');
    setIsSubmitting(false);
  };

  const mergedModals = getMergedAddonModals(
    addonModals,
    allTreats,
    allSupplements,
  );

  const { modal, onAddonDetailsClick } = useAddonModal(mergedModals);
  return (
    <StyledQuickAddExtrasContainer>
      <SectionTitleContainer>
        <Heading
          headingLevel="h3"
          typography={{ desktop: 'heading2', mobile: 'heading2' }}
          headingText={sectionTitle}
        />
        <LinkContainer>
          <SmolLink
            href={editExtrasLink}
            onClick={() => reporter.tag(userClicksSeeAllExtras())}
          >
            {seeExtrasLinkText}
          </SmolLink>
        </LinkContainer>
      </SectionTitleContainer>
      <ExtrasContainer>
        {isSubscriptionAddonProductsValidating && (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
        {topAddons.map(addon => (
          <Extra key={addon.sku}>
            <ExtraInfoContainer>
              <ExtraImage
                tabIndex="0"
                onClick={() => onAddonDetailsClick(addon.sku)}
              >
                {addon?.badge && (
                  <BadgeContainer>
                    <PillText
                      text={addon.badge}
                      variant={addon.badgeDropdown}
                    />
                  </BadgeContainer>
                )}
                <ResponsiveImage {...addon.image} />
              </ExtraImage>
              <ExtraInfo>
                <ProductName>{addon.productName}</ProductName>
                <Size>
                  1 {addon.unit}
                  {addon.recurrence === 'null'
                    ? ' in every box'
                    : ', next box only'}
                </Size>
                <Price>${addon.price}</Price>
              </ExtraInfo>
            </ExtraInfoContainer>
            <AddButtonContainer>
              <Button
                onClick={() => {
                  reporter.tag(userClicksAddExtra(addon.sku));
                  handleOnQuickAddExtraClick({
                    productName: addon.productName,
                    frequency: 1,
                    sku: addon.sku,
                    quantity: 1,
                    recurrence: JSON.parse(addon.recurrence),
                  });
                }}
                disabled={
                  (isSubmitting && activeSku !== '') ||
                  isSubscriptionAddonProductsValidating
                }
                isLoading={
                  (isSubmitting && activeSku === addon.sku) ||
                  isSubscriptionAddonProductsValidating
                }
                horizontalPadding={6}
                verticalPadding={4}
                variant="chia"
              >
                + Add
              </Button>
            </AddButtonContainer>
          </Extra>
        ))}
      </ExtrasContainer>
      <AddOnQuickView
        {...modal}
        onSubmitAddonAddition={handleOnQuickAddExtraClick}
        addonSelectionState={[]}
        defaultValues={{
          quantity: 1,
          frequency: 1,
          recurrence: null,
        }}
      />
    </StyledQuickAddExtrasContainer>
  );
};

QuickAddExtrasContainer.displayName = 'QuickAddExtrasContainer';

QuickAddExtrasContainer.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  seeExtrasLinkText: PropTypes.string.isRequired,
  editExtrasLink: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: {
      pet_id: PropTypes.string,
      direct_edit: PropTypes.bool.isRequired,
    },
  }).isRequired,
  recommendedAddons: PropTypes.array.isRequired,
  addonModals: PropTypes.array.isRequired,
  allTreats: PropTypes.array.isRequired,
  allSupplements: PropTypes.array.isRequired,
  onQuickAddExtraClick: PropTypes.func.isRequired,
  isSubscriptionAddonProductsValidating: PropTypes.bool.isRequired,
  subscribedAddons: PropTypes.array.isRequired,
};
