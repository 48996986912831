'use client';
import styled from 'styled-components';

export const StyledWelcomeBack = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Headline = styled.h2`
  ${props => props.theme.typography.heading1};
  color: ${props => props.theme.colors.text.primary};
  text-align: center;
  margin-bottom: ${props => props.theme.spacing(2)};
  @media ${props => props.theme.tablet} {
    ${props => props.theme.typography.heading2};
    text-align: left;
    margin-bottom: ${props => props.theme.spacing(4)};
  }
`;

export const Subheadline = styled.p`
  ${props => props.theme.typography.body};
  text-align: center;
  color: ${props => props.theme.colors.text.primary};
  @media ${props => props.theme.tablet} {
    text-align: left;
    margin: 0;
  }
`;
