'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Footer, LoadingView } from '@web/components';
import { Heading } from '@web/atoms';
import { AccountHeader } from '../AccountHeader';
import { RedirectToLogin, Redirect } from '../../router';
import { PupForm } from '../PupForm';
import { useSessionCustomer } from '../../customer';
import { Subheading, WrapperHeading, Container, Content } from './styles';

export const AddPupContainer = ({
  heading,
  subheading,
  labelName,
  labelGender,
  labelBirth,
  labelBreeds,
  weightLabel,
  idealWeightLabel,
  idealWeightHelp,
  labelSpayed,
  labelAllergies,
  labelAllergySearch,
  labelActivityLevel,
  gender,
  spayed,
  activityLevels,
  defaultValues,
  labelButtonSave,
  labelButtonCancel,
  cancelUrl,
  onCancel,
  footerContent,
  onPetSave,
  links,
  logoUrl,
  logoSrText,
}) => {
  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { query } = useRouter();
  const cancelled = query.cancelled;

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isCustomerLoading) {
    return <LoadingView />;
  }
  // It receives the cancelled only when it the user clicks on the PlanSummary
  // since we need to show the loading view to don't show the user a blank form
  // add in cancelled query parameter so handler is returned out of
  if (cancelled) {
    return (
      <Redirect to={{ pathname: '/account/', query: { cancelled: true } }} />
    );
  }

  const onSubmit = pet => {
    setIsSubmitting(true);
    onPetSave(pet);
    setIsSubmitting(false);
  };

  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <Container>
        <Content>
          <WrapperHeading>
            <Heading
              headingLevel="h1"
              typography={'heading1'}
              headingText={heading}
              position="center"
            />
            <Subheading subheading={true}>{subheading}</Subheading>
          </WrapperHeading>
          <PupForm
            labelName={labelName}
            labelGender={labelGender}
            labelBirth={labelBirth}
            labelBreeds={labelBreeds}
            weightLabel={weightLabel}
            idealWeightLabel={idealWeightLabel}
            idealWeightHelp={idealWeightHelp}
            labelSpayed={labelSpayed}
            labelAllergies={labelAllergies}
            labelAllergySearch={labelAllergySearch}
            labelActivityLevel={labelActivityLevel}
            gender={gender}
            spayed={spayed}
            activityLevels={activityLevels}
            labelButtonSave={labelButtonSave}
            labelButtonCancel={labelButtonCancel}
            cancelUrl={cancelUrl}
            onCancel={onCancel}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            isSubmitting={isSubmitting}
          />
        </Content>
      </Container>
      <Footer {...footerContent} />
    </>
  );
};

AddPupContainer.displayName = 'AddPupContainer';
AddPupContainer.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  labelGender: PropTypes.string.isRequired,
  labelBirth: PropTypes.string.isRequired,
  labelBreeds: PropTypes.string.isRequired,
  weightLabel: PropTypes.string.isRequired,
  idealWeightLabel: PropTypes.string.isRequired,
  idealWeightHelp: PropTypes.string.isRequired,
  labelSpayed: PropTypes.string.isRequired,
  labelAllergies: PropTypes.string.isRequired,
  labelAllergySearch: PropTypes.string.isRequired,
  labelActivityLevel: PropTypes.string.isRequired,
  gender: PropTypes.array.isRequired,
  spayed: PropTypes.array.isRequired,
  activityLevels: PropTypes.array.isRequired,
  defaultValues: PropTypes.object.isRequired,
  labelButtonSave: PropTypes.string.isRequired,
  labelButtonCancel: PropTypes.string.isRequired,
  cancelUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  onCancel: PropTypes.func.isRequired,
  footerContent: PropTypes.object,
  onPetSave: PropTypes.func.isRequired,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
};
