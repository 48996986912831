import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { getCompoundSentenceList } from '@format/list';
import { interpolate } from '@format/interpolate';
import { Button } from '@ui/button';
import { PromoPill, ResponsiveImage } from '@web/molecules';
import { ErrorText } from '@web/atoms';
import { useReporter } from '../../reporter';
import boxImage from '../../images/onboarding/box.svg';
import { confirmClicked } from './events';
import {
  Discount,
  StyledBoxSummaryExtras,
  Headline,
  ImageContainer,
  FlexRowContainer,
  RecipesInfoContainer,
  SelectedRecipes,
  MealPrepTypeContainer,
  MealPrepTypeLabel,
  Checkout,
  SummaryInfo,
  Top,
  ErrorLink,
  HrTag,
  RecipeInfoSection,
  ExtrasTextContainer,
  ExtrasInfoContainer,
  ChargeText,
  CranberryText,
  CranberryTextSpan,
} from './styles.js';

export const BoxSummaryExtras = ({
  headline,
  recipes,
  mealPrepText,
  pupName,
  discountPercentage,
  totalTax,
  mealPlanPriceInfo,
  addons,
  totalDiscountAmount,
  totalAfterDiscount,
  paymentInfo,
  totalBeforeDiscount,
  button,
  error,
  offerCategory,
  bakedUnavailable,
  offerCodeIsValid,
}) => {
  const reporter = useReporter();
  const trackEvent = confirmClicked();
  const onConfirmClick = event => {
    button?.onClick?.(event);
    return reporter.tag(trackEvent);
  };
  let [cardCarrier = '', paymentEnding = ''] = paymentInfo;
  paymentEnding = paymentEnding.toLowerCase();
  const freshRecipes = capitalize(getCompoundSentenceList(recipes.fresh));
  const bakedRecipes = capitalize(getCompoundSentenceList(recipes.dry));

  return (
    <StyledBoxSummaryExtras errorBorder={error}>
      <Top>
        <FlexRowContainer>
          <ImageContainer>
            <ResponsiveImage
              src={boxImage.src}
              alt="Ollie box"
              width={boxImage.width}
              height={boxImage.height}
            />
          </ImageContainer>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '70%' }}
          >
            <Headline>{headline}</Headline>
            <Discount>
              <PromoPill
                message="off your next box"
                variant="egg"
                category={offerCategory}
              />
            </Discount>
          </div>
        </FlexRowContainer>
        <HrTag />

        <SummaryInfo>
          <RecipeInfoSection>
            <MealPrepTypeContainer>
              <MealPrepTypeLabel>
                {interpolate(mealPrepText, { pupName })}
              </MealPrepTypeLabel>
              {offerCodeIsValid ? (
                <MealPrepTypeLabel>
                  <CranberryTextSpan>
                    ${mealPlanPriceInfo.price}
                  </CranberryTextSpan>{' '}
                  ${mealPlanPriceInfo.discounted_price}
                </MealPrepTypeLabel>
              ) : (
                <MealPrepTypeLabel>
                  ${mealPlanPriceInfo.price}
                </MealPrepTypeLabel>
              )}
            </MealPrepTypeContainer>

            {Boolean(freshRecipes.length) && (
              <RecipesInfoContainer>
                {/* <RecipesInfoLabel>Fresh Recipes: {freshRecipes}</RecipesInfoLabel> */}
                <SelectedRecipes>Fresh Recipes: {freshRecipes}</SelectedRecipes>
              </RecipesInfoContainer>
            )}
            {Boolean(bakedRecipes.length) && (
              <RecipesInfoContainer>
                {/* <RecipesInfoLabel>Baked Recipes:</RecipesInfoLabel> */}
                <SelectedRecipes>Baked Recipes: {bakedRecipes}</SelectedRecipes>
              </RecipesInfoContainer>
            )}
          </RecipeInfoSection>

          <HrTag />

          {Boolean(addons.length) && (
            <>
              <RecipeInfoSection>
                <MealPrepTypeContainer>
                  <MealPrepTypeLabel>Extras</MealPrepTypeLabel>
                </MealPrepTypeContainer>

                <ExtrasTextContainer>
                  {addons.map(addonInfo => {
                    const {
                      product_name,
                      product_type,
                      quantity,
                      discounted_price,
                      price,
                      sku,
                    } = addonInfo;
                    const extraText = `${product_name} (${quantity} ${
                      product_type === 'SP'
                        ? `jar${quantity > 1 ? 's' : ''}`
                        : sku.includes('FR')
                        ? `pint${quantity > 1 ? 's' : ''}`
                        : `bag${quantity > 1 ? 's' : ''}`
                    })`;
                    return (
                      <ExtrasInfoContainer key={product_name}>
                        <SelectedRecipes>{extraText}</SelectedRecipes>
                        {offerCodeIsValid ? (
                          <MealPrepTypeLabel>
                            <CranberryTextSpan>${price}</CranberryTextSpan> $
                            {discounted_price}
                          </MealPrepTypeLabel>
                        ) : (
                          <MealPrepTypeLabel>${price}</MealPrepTypeLabel>
                        )}
                      </ExtrasInfoContainer>
                    );
                  })}
                </ExtrasTextContainer>
              </RecipeInfoSection>
              <HrTag />
            </>
          )}
          <RecipeInfoSection>
            <MealPrepTypeContainer>
              <CranberryText>SHIPPING</CranberryText>
              <CranberryText>FREE</CranberryText>
            </MealPrepTypeContainer>

            {offerCodeIsValid && (
              <MealPrepTypeContainer>
                <CranberryText>
                  FOOD DISCOUNT APPLIED {discountPercentage}% OFF
                </CranberryText>
                <CranberryText>-${totalDiscountAmount}</CranberryText>
              </MealPrepTypeContainer>
            )}

            <MealPrepTypeContainer>
              <MealPrepTypeLabel>TAXES</MealPrepTypeLabel>
              <MealPrepTypeLabel>${totalTax}</MealPrepTypeLabel>
            </MealPrepTypeContainer>
          </RecipeInfoSection>
          <HrTag />
        </SummaryInfo>
      </Top>
      <Checkout>
        {paymentInfo.length && offerCodeIsValid ? (
          <ChargeText>
            <CranberryTextSpan>{totalBeforeDiscount}</CranberryTextSpan> $
            {totalAfterDiscount} will be charged to your {cardCarrier}{' '}
            {paymentEnding}
          </ChargeText>
        ) : paymentInfo.length ? (
          <ChargeText>
            {totalBeforeDiscount} will be charged to your {cardCarrier}{' '}
            {paymentEnding}
          </ChargeText>
        ) : (
          ''
        )}
        <Button
          onClick={onConfirmClick}
          disabled={button.disabled}
          isLoading={button.loading}
          ref={button.ref}
          horizontalPadding={12}
          verticalPadding={6}
          variant="chia"
        >
          {button.ctaText}
        </Button>
      </Checkout>
      {error != null && (
        <ErrorText>
          {error.message}
          <ErrorLink href={error.link}>{error.linkText}</ErrorLink>
        </ErrorText>
      )}
      {bakedUnavailable && (
        <ErrorText>
          Our baked plans are currently unavailable. Please select a fresh or
          mixed plan to reactivate.
        </ErrorText>
      )}
    </StyledBoxSummaryExtras>
  );
};

BoxSummaryExtras.displayName = 'BoxSummaryExtras';

BoxSummaryExtras.propTypes = {
  headline: PropTypes.string.isRequired,
  mealPrepText: PropTypes.string.isRequired,
  discountPercentage: PropTypes.string.isRequired,
  recipes: PropTypes.shape({
    fresh: PropTypes.arrayOf(PropTypes.string.isRequired),
    dry: PropTypes.arrayOf(PropTypes.string.isRequired),
  }),
  addons: PropTypes.arrayOf(
    PropTypes.shape({
      product_name: PropTypes.string.isRequired,
      product_type: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      discounted_price: PropTypes.string.isRequired,
    }),
  ),
  mealPlanPriceInfo: PropTypes.shape({
    discounted_price: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
  totalDiscountAmount: PropTypes.string.isRequired,
  totalAfterDiscount: PropTypes.string.isRequired,
  totalBeforeDiscount: PropTypes.string.isRequired,
  totalTax: PropTypes.string.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
  }),
  pupName: PropTypes.string.isRequired,
  paymentInfo: PropTypes.arrayOf(PropTypes.string.isRequired),
  button: PropTypes.shape({
    ref: PropTypes.any,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    ctaText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }),
  offerCategory: PropTypes.string,
  bakedUnavailable: PropTypes.bool,
  offerCodeIsValid: PropTypes.bool,
};
