import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { RadioCard } from '@web/atoms';
import { CardContainer } from './styles';

export const DateCards = ({ choices, name, control }) => {
  return (
    <div role="tablist">
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <CardContainer>
            {choices.map(choice => (
              <RadioCard
                {...field}
                title={choice.title}
                body={choice.body}
                id={choice.id}
                key={choice.id}
                active={field.value === choice.id}
                ariaControls={`panel-${choice.id}`}
              />
            ))}
          </CardContainer>
        )}
      />
    </div>
  );
};

DateCards.displayName = 'DateCards';

DateCards.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};
