export const userViewEditPayment = () => ({
  type: 'View Edit Payment Info page',
});

export const paymentCardClicked = () => ({
  type: 'Add card clicked',
});

export const applePayButtonClicked = () => ({
  type: 'Add ApplePay clicked',
});

export const googlePayButtonClicked = () => ({
  type: 'Add GooglePay clicked',
});

export const userUpdatedPaymentMethod = () => ({
  type: 'Payment updated',
});

export const billingInformationCompleted = walletType => ({
  type: 'Billing Information Completed',
  data: {
    wallet_type: walletType,
  },
});
