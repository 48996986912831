export const userViewsMyInfo = () => ({
  type: 'Views My Info Page',
});

export const clickEditPassword = () => ({
  type: 'User clicks Change Password',
});

export const clickOrderHistory = () => ({
  type: 'Clicks order history',
});

export const clickEditDelivery = () => ({
  type: 'User clicks Edit Delivery Address ',
});

export const clickEditPayment = () => ({
  type: 'User clicks Edit Payment Info',
});

export const clickLogout = () => ({
  type: 'User clicks logout',
});

export const clickEditSubscriptions = () => ({
  type: 'User clicks Edit Subscriptions',
});
