import React from 'react';
import PropTypes from 'prop-types';
import { formatAddons } from './dataParsers';
import { AddonsNavLink } from './components';

export const AddonsNavLinkContainer = ({
  addons,
  title,
  onInfoClick,
  navLinks,
}) => {
  const { treats, supplements } = formatAddons(addons);
  return (
    <AddonsNavLink
      title={title}
      onInfoClick={onInfoClick}
      navLinks={navLinks}
      addons={{ treats, supplements }}
    />
  );
};

AddonsNavLinkContainer.displayName = 'AddonsNavLinkContainer';
AddonsNavLinkContainer.propTypes = {
  title: PropTypes.string.isRequired,
  onInfoClick: PropTypes.func,
  addons: PropTypes.arrayOf(
    PropTypes.shape({
      product_name: PropTypes.string.isRequired,
      product_type: PropTypes.string.isRequired,
    }),
  ),
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      addonType: PropTypes.string.isRequired,
      href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }).isRequired,
  ).isRequired,
};
