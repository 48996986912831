import React from 'react';
import PropTypes from 'prop-types';
import { AccountSubscriptions } from './components';

export const AccountSubscriptionsContainer = ({
  title,
  href,
  subscriptions,
  onSubscriptionsClick,
}) => (
  <AccountSubscriptions
    title={title}
    href={href}
    subscriptions={subscriptions}
    onSubscriptionsClick={onSubscriptionsClick}
  />
);

AccountSubscriptionsContainer.displayName = 'AccountSubscriptionsContainer';
AccountSubscriptionsContainer.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      petName: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSubscriptionsClick: PropTypes.func.isRequired,
};
