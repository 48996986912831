'use client';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-radius: 38px;
  background-color: ${props => props.theme.colors.background.lightest};
  padding: ${props => props.theme.spacing(6)};
  /* Compensate for 10px of extra spacing in Icon */
  padding-right: calc(${props => props.theme.spacing(6)} - 10px);
  width: 100%;
  min-height: 100px;
  align-items: center;
  position: relative;
  margin: ${props => props.theme.spacing(2, 0, 9)};
`;

export const InfoListContainer = styled.div`
  flex: 1;
`;

export const IconContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding-right: ${props => props.theme.spacing(2)};
  padding-left: ${props => props.theme.spacing(8)};
  svg {
    max-height: 25px;
  }
`;

export const AnchorContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const Anchor = styled.a`
  ${props => props.theme.typography.heading4}
  color: ${props => props.theme.colors.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};
  &::before {
    cursor: pointer;
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const InfoLine = styled.p`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.text.secondary};
`;
