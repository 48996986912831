import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from './react-datepicker';
import { StyledDatePicker } from './styles';
import { Header } from './Header';

export const DatePicker = ({
  selected,
  onChange,
  disabledDates = [],
  minDate,
  maxDate,
  setMaxDateReached,
}) => {
  return (
    <StyledDatePicker>
      <ReactDatePicker
        selected={selected}
        onChange={onChange}
        inline
        calendarClassName="date-picker-container"
        dayClassName={() => 'date-picker-day'}
        formatWeekDay={weekDay => weekDay[0]}
        renderCustomHeader={props => {
          return <Header {...props} setMaxDateReached={setMaxDateReached} />;
        }}
        excludeDates={disabledDates}
        minDate={minDate}
        maxDate={maxDate}
      />
    </StyledDatePicker>
  );
};

DatePicker.displayName = 'DatePicker';
DatePicker.propTypes = {
  selected: PropTypes.object,
  onChange: PropTypes.func,
  disabledDates: PropTypes.arrayOf(PropTypes.object),
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  setMaxDateReached: PropTypes.func,
};
