'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { LoadingView, ProtectedRoute } from '@web/components';
import { BoxShippingSoonContainer } from '../BoxShippingSoonContainer';
import { useEditDeliveryProtectedRoute } from './useEditDeliveryProtectedRoute';

export const EditDeliveryProtectedRoute = ({
  links,
  logoUrl,
  logoSrText,
  shippingBoxSoon,
  children,
}) => {
  const { isAllowed, nextDeliveryDate, isLoading } =
    useEditDeliveryProtectedRoute();

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <ProtectedRoute
      isAllowed={isAllowed}
      fallbackRender={() => (
        <BoxShippingSoonContainer
          links={links}
          logoUrl={logoUrl}
          logoSrText={logoSrText}
          content={shippingBoxSoon}
          nextDeliveryDate={nextDeliveryDate}
        />
      )}
    >
      {children}
    </ProtectedRoute>
  );
};
EditDeliveryProtectedRoute.displayName = 'EditDeliveryProtectedRoute';

EditDeliveryProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  shippingBoxSoon: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
};
