'use client';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { LOGGEDIN_LINKS } from '@web/molecules';
import { Heading } from '@web/atoms';
import { Footer, BoxSummary } from '@web/components';
import { ResponsiveImage } from '@web/molecules/ResponsiveImage';
import { InfoLink } from '../InfoLink';
import { RecipeNavLink } from '../RecipeNavLink';
import { AccountHeader } from '../AccountHeader';
import { FOOTER_CONTENT } from '../../testing/constants';
import { useReporter } from '../../reporter';
import { determinePlanType } from '../../products';
import warningIcon from '../../images/Icons/warning-icon.svg';
import {
  useExperiment,
  HIDE_BAKED_ONLY_MEMBER_EXPERIENCE,
} from '../../experiments';
import {
  onInfoSectionClicked,
  onPlanSummaryError,
  onPlanSummaryCancel,
} from './events';
import {
  Subtitle,
  StyledPlanSummary,
  InfoContainer,
  Container,
  ButtonContainer,
  BoxSummaryContainer,
  Disclaimer,
  NoPaymentMethodText,
} from './styles.js';

export const PlanSummary = ({
  headline,
  name,
  pupsInfo,
  recipesInfo,
  paymentInfo,
  paymentMethodIsActive,
  deliveryInfo,
  subheadingText,
  confirmationText,
  cancelButtonLabel,
  boxheadline,
  offerCategory,
  discountedPeriodAmount,
  totalAmount,
  totalPacks,
  totalBags,
  period,
  errorMessage,
  onSubmit,
  onCancel,
  reactivationMode,
  pupInfoTarget,
  mealPrepTarget,
  dryRecipesTarget,
  freshRecipesTarget,
  paymentTarget,
  deliveryTarget,
  cancelButtonTarget,
  isSubmitting,
  confirmationButtonText = 'Confirm',
  portionSize,
  weeksOfFood,
}) => {
  const reporter = useReporter();
  const onInfoTrack = section => () => {
    reporter.tag(onInfoSectionClicked(section, reactivationMode));
  };
  const { value: hideBaked } = useExperiment(
    HIDE_BAKED_ONLY_MEMBER_EXPERIENCE,
    false,
  );

  useEffect(() => {
    if (errorMessage) {
      reporter.tag(onPlanSummaryError(errorMessage));
    }
  }, [reporter, errorMessage]);

  const onCancelClick = () => {
    reporter.tag(onPlanSummaryCancel());
    onCancel?.();
  };
  const mealPrepType = determinePlanType(recipesInfo, portionSize);

  const bakedUnavailable = mealPrepType === 'baked' && hideBaked;

  const formatMealPrep = () => {
    if (mealPrepType === 'half_fresh') return 'Half Fresh Plan';
    if (mealPrepType === 'fresh') return 'Full Fresh Plan';
    return `A ${mealPrepType} bowl`;
  };

  return (
    <StyledPlanSummary>
      <AccountHeader
        links={LOGGEDIN_LINKS}
        logoUrl="/"
        logoSrText="Ollie logo"
        name={name}
      />
      <Container>
        <Heading
          headingLevel="h1"
          headingText={headline}
          typography="heading1"
          position="center"
        />
        <Subtitle>{subheadingText}</Subtitle>
        <InfoContainer>
          <InfoLink
            title="Pup's info"
            href={pupInfoTarget}
            infoList={pupsInfo}
            cancelTarget={cancelButtonTarget}
            onInfoClick={onInfoTrack('Pup Info')}
          />
          <InfoLink
            title="Meal Plan"
            href={mealPrepTarget}
            infoList={[formatMealPrep()]}
            onInfoClick={onInfoTrack('Meal Plan')}
          />
          <RecipeNavLink
            title="Recipes"
            freshHref={freshRecipesTarget}
            bakedHref={dryRecipesTarget}
            recipes={recipesInfo}
            onInfoClick={onInfoTrack('Recipes')}
          />
          <InfoLink
            title={'Payment method'}
            href={paymentTarget}
            infoList={[
              paymentInfo.length > 0 && paymentMethodIsActive ? (
                paymentInfo
              ) : (
                <NoPaymentMethodText>
                  <ResponsiveImage
                    src={warningIcon}
                    alt={'No Payment Method added'}
                    width={24}
                    height={24}
                  />
                  No payment method added
                </NoPaymentMethodText>
              ),
            ]}
            onInfoClick={onInfoTrack('Payment')}
          />
          <InfoLink
            title="Delivery info"
            href={deliveryTarget}
            infoList={deliveryInfo}
            onInfoClick={onInfoTrack('Delivery')}
          />
          <BoxSummaryContainer>
            <BoxSummary
              headline={boxheadline}
              recipes={recipesInfo}
              mealPrepText={formatMealPrep()}
              totalPacks={totalPacks}
              totalBags={totalBags}
              weeksOfFood={weeksOfFood}
              discountedPeriodAmount={discountedPeriodAmount}
              periodAmount={totalAmount}
              period={period}
              offerCategory={offerCategory}
              button={{
                loading: isSubmitting,
                disabled:
                  isSubmitting ||
                  paymentInfo.length === 0 ||
                  !paymentMethodIsActive ||
                  bakedUnavailable,
                ctaText: confirmationButtonText,
                onClick: onSubmit,
              }}
              bakedUnavailable={bakedUnavailable}
              error={
                errorMessage
                  ? {
                      message: errorMessage,
                      link: '/account/edit-payment',
                      linkText:
                        ' Please add a valid payment method to continue with your order',
                    }
                  : undefined
              }
            />
          </BoxSummaryContainer>
        </InfoContainer>
        <Disclaimer>{confirmationText}</Disclaimer>
        <ButtonContainer>
          <LinkButton
            horizontalPadding={30}
            verticalPadding={3}
            variant="secondary"
            type="cancel"
            href={cancelButtonTarget}
            onClick={onCancelClick}
          >
            {cancelButtonLabel}
          </LinkButton>
        </ButtonContainer>
      </Container>
      <Footer {...FOOTER_CONTENT} />
    </StyledPlanSummary>
  );
};

PlanSummary.displayName = 'PlanSummary';

PlanSummary.propTypes = {
  name: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  subheadingText: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  pupsInfo: PropTypes.arrayOf(PropTypes.string).isRequired,
  recipesInfo: PropTypes.shape({
    fresh: PropTypes.arrayOf(PropTypes.string).isRequired,
    dry: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  paymentInfo: PropTypes.arrayOf(PropTypes.string).isRequired,
  deliveryInfo: PropTypes.arrayOf(PropTypes.string).isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  cancelButtonTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  discountedPeriodAmount: PropTypes.string,
  boxheadline: PropTypes.string.isRequired,
  totalPacks: PropTypes.number.isRequired,
  totalBags: PropTypes.number.isRequired,
  totalAmount: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  reactivationMode: PropTypes.bool,
  pupInfoTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  mealPrepTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  freshRecipesTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  dryRecipesTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  paymentTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  deliveryTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  offerCategory: PropTypes.string,
  confirmationButtonText: PropTypes.string,
  portionSize: PropTypes.string,
  weeksOfFood: PropTypes.number.isRequired,
  paymentMethodIsActive: PropTypes.bool.isRequired,
};
