'use client';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Cell, Grid } from '@web/atoms';
import { InfoLink } from '../InfoLink';
import { useReporter } from '../../reporter';
import { InfoCardContainer } from './styles';
import { userViewEditUserInfo } from './events';

export const EditMyAccountInfoForm = ({ userAccountInformation }) => {
  const reporter = useReporter();

  useEffect(() => {
    reporter.tag(userViewEditUserInfo());
  }, [reporter]);

  return (
    <Grid gap={{ row: 3, column: 3 }} columns={{ tablet: 1, mobile: 1 }}>
      {userAccountInformation.map((accountInfo, index) => (
        <Cell key={index}>
          <InfoCardContainer>
            <InfoLink {...accountInfo} />
          </InfoCardContainer>
        </Cell>
      ))}
    </Grid>
  );
};

EditMyAccountInfoForm.displayName = 'EditMyAccountInfoForm';

EditMyAccountInfoForm.propTypes = {
  userAccountInformation: PropTypes.arrayOf(PropTypes.object).isRequired,
};
