import {
  format,
  addDays,
  isBefore,
  max,
  parseISO,
  startOfDay,
  differenceInDays,
} from 'date-fns';

export const isDateAvailable = (possibleDates, date) =>
  possibleDates.includes(format(date, 'yyyy-MM-dd'));

const getNextAvailableDate = (possibleDates, date, maxDate) => {
  if (!date || possibleDates.length === 0 || isBefore(maxDate, date)) {
    return null;
  }
  if (isDateAvailable(possibleDates, date)) {
    return date;
  }
  return getNextAvailableDate(possibleDates, addDays(date, 1), maxDate);
};

export const approximateSkippedDelivery = (nextDeliveryDate, frequency) => {
  const daysToNextShipment = differenceInDays(
    nextDeliveryDate,
    startOfDay(new Date()),
  );
  const fullWeeks = Math.floor(daysToNextShipment / 7);
  const partialWeek = daysToNextShipment % 7 < 4 ? 0 : 1;
  return fullWeeks + partialWeek + frequency;
};

export const findNextAvailableDate = (possibleDates, date) => {
  const allDates = possibleDates.map(possibleDate => parseISO(possibleDate));
  return getNextAvailableDate(possibleDates, date, max(allDates));
};
