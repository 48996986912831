'use client';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  padding-top: ${props => props.theme.spacing(14)};
  background-color: ${({ theme }) => theme.colors.background.base};
`;

export const WarningText = styled.p`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.fontFamily.base}
  color: ${({ theme }) => theme.colors.status.warningDark};
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${props => props.theme.spacing(2)};

  @media (max-width: 650px) {
    align-items: flex-start;
  }
`;
