'use client';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinkButton } from '@ui/button';
import { RadioCard, SmolLink, PillText } from '@web/atoms';
import BoxImage from '../../images/mx-ollie-box.png';
import { useReporter } from '../../reporter';
import { clickLinkEvent, updateBoxFrequencyEvent } from './events.js';
import { schema } from './schema.js';
import {
  CardContainer,
  ButtonContainer,
  LinkContainer,
  ChipContainer,
} from './styles';

export const FrequencyForm = ({
  choices,
  defaultValues,
  frequencyHref,
  cancelButtonText,
  submitButtonText,
  frequencyLabel,
  chipList,
  onSubmit,
  cancelBtnTarget,
  isSubmitting,
  showUIUpdates,
}) => {
  const { control, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const reporter = useReporter();

  const onSubmitWithReporter = data => {
    const existingChoice = choices.find(
      choice => choice.sku === defaultValues.sku,
    );
    const newChoice = choices.find(choice => choice.sku === data.sku);
    reporter.tag(
      updateBoxFrequencyEvent(
        existingChoice.weeks_of_food,
        newChoice.weeks_of_food,
      ),
    );
    onSubmit(newChoice);
  };

  const chipOptions = chipList.items.map(item => item.body);

  const boxImageSize = [
    { height: 45, width: 45 },
    { height: 65, width: 65 },
    { height: 85, width: 85 },
  ];

  const onLinkClick = () => {
    reporter.tag(clickLinkEvent());
  };

  return (
    <form onSubmit={handleSubmit(onSubmitWithReporter)}>
      <Controller
        name="sku"
        control={control}
        render={({ field: { ref, ...field } }) => (
          <CardContainer center={choices.length < 3}>
            {choices.map((choice, i) => {
              const currentChip = chipOptions[i] || '';
              const middleOptionIndex =
                choices.length % 2 === 0
                  ? choices.indexOf(choices[Math.floor(choices.length / 2) - 1])
                  : choices.indexOf(choices[Math.floor(choices.length / 2)]);
              const lastOption = i + 1 === choices.length;
              //if there are more than three options, should only display a chip on the
              // first, middle, and last cards
              const noChipOption =
                choices.length > 3 &&
                i !== 0 &&
                i !== middleOptionIndex &&
                !lastOption;
              return (
                <RadioCard
                  {...field}
                  title={choice.title}
                  body={choice.body}
                  id={choice.sku}
                  key={choice.sku}
                  active={field.value === choice.sku}
                  textAlign={showUIUpdates ? 'left' : 'center'}
                  footer={
                    showUIUpdates && choices?.length > 1 && lastOption
                      ? '🌱 Lower climate impact'
                      : undefined
                  }
                  footerColor="status.successPrimary"
                  image={
                    showUIUpdates
                      ? {
                          src: BoxImage,
                          alt: 'ollie box',
                          height: boxImageSize[i]?.height || 105,
                          width: boxImageSize[i]?.width || 105,
                        }
                      : undefined
                  }
                >
                  {showUIUpdates && choices?.length > 1 && !noChipOption && (
                    <ChipContainer data-testid="frequency-chip">
                      <PillText
                        text={
                          choices.length > 3 && i === middleOptionIndex
                            ? chipOptions[1]
                            : lastOption
                            ? chipOptions[2]
                            : currentChip
                        }
                        variant="spinach"
                      />
                    </ChipContainer>
                  )}
                </RadioCard>
              );
            })}
          </CardContainer>
        )}
      />
      <ButtonContainer>
        <Button type="submit" disabled={isSubmitting}>
          {submitButtonText}
        </Button>
        <LinkButton href={cancelBtnTarget} variant="secondary" fullWidth>
          {cancelButtonText}
        </LinkButton>
      </ButtonContainer>
      <LinkContainer onClick={onLinkClick}>
        <SmolLink href={frequencyHref}>{frequencyLabel}</SmolLink>
      </LinkContainer>
    </form>
  );
};

FrequencyForm.displayName = 'FrequencyForm';

FrequencyForm.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      weeks_of_food: PropTypes.number.isRequired,
      sku: PropTypes.string.isRequired,
    }),
  ).isRequired,
  defaultValues: PropTypes.shape({
    sku: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  frequencyHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  frequencyLabel: PropTypes.string.isRequired,
  chipList: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  cancelBtnTarget: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  showUIUpdates: PropTypes.bool.isRequired,
};
