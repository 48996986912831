'use client';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { LoadingView, Footer } from '@web/components';
import { LOGGEDIN_LINKS } from '@web/molecules';
import { Heading, ButtonLink } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules/ResponsiveImage';
import { useSessionCustomer, useAddress } from '../../customer';
import { AccountHeader } from '../AccountHeader';
import { useReporter } from '../../reporter';
import { RedirectToLogin } from '../../router';
import { usePayment } from '../../payment';
import { useSubscriptions } from '../../subscriptions';
import { useExperiment } from '../../experiments';
import { useOrderHistory } from '../../orders';
import { AccountSubscriptionsContainer } from '../AccountSubscriptionsContainer';
import { EditMyAccountInfoForm } from '../EditMyAccountInfoForm';
import { passwordInfo } from '../EditMyAccountInfoForm/constants';
import { useSession } from '../../api';
import { TOAST_CLIENT_ERROR_MESSAGE, useToast } from '../../Toast';
import { SHOW_ORDER_HISTORY } from '../../experiments/constants';
import warningIcon from '../../images/Icons/warning-icon.svg';
import {
  userViewsMyInfo,
  clickEditPassword,
  clickEditDelivery,
  clickEditPayment,
  clickEditSubscriptions,
  clickLogout,
  clickOrderHistory,
} from './events';
import {
  Container,
  HeadingContainer,
  LinkContainer,
  StyledInfoContainer,
  NoPaymentMethodText,
} from './styles';

export const MyInfoContainer = ({
  paymentTitleLabel,
  orderTitleLabel,
  paymentPageUrl,
  addressTitleLabel,
  addressPageUrl,
  passwordTitleLabel,
  passwordPageUrl,
  subscriptionsTitleLabel,
  subscriptionsPageUrl,
  headerTitleLabel,
  footer,
}) => {
  const reporter = useReporter();
  const { logout } = useSession();
  const router = useRouter();
  const { showToast } = useToast();

  useEffect(() => {
    reporter.tag(userViewsMyInfo());
  }, [reporter]);

  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();
  const { subscriptions, isLoading: areSubscriptionsLoading } =
    useSubscriptions();
  const { orderCount, isLoading: isOrderHistoryLoading } = useOrderHistory(1);
  const { address } = useAddress(customer?.id);
  const { payment } = usePayment(customer?.id);
  let deliveryInfo = [];
  let paymentInfo = [];
  let subscriptionsInfo = [];

  const { value: showOrderHistory } = useExperiment(SHOW_ORDER_HISTORY, false);

  const isLoading =
    isCustomerLoading || areSubscriptionsLoading || isOrderHistoryLoading;

  if (!isLoading && !isActive) {
    return <RedirectToLogin />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  paymentInfo =
    payment && payment?.is_active
      ? ['Payment method', `${payment.brand} *****${payment.last_four_digits}`]
      : [
          <NoPaymentMethodText key="NoPaymentText">
            <ResponsiveImage
              src={warningIcon}
              alt={'No Payment Method added'}
              width={24}
              height={24}
            />
            No payment method added
          </NoPaymentMethodText>,
        ];

  if (address) {
    deliveryInfo = [
      address.address1,
      address.address2,
      `${address.city}, ${address.state} ${address.zip_code}`,
    ].filter(Boolean);
  }

  if (subscriptions) {
    subscriptionsInfo = subscriptions.map(({ pet_name, status }) => ({
      petName: pet_name,
      status,
    }));
  }

  const logOut = async () => {
    const { Gladly } = window;
    reporter.tag(clickLogout());
    if (Gladly && Gladly.clearUser) Gladly.clearUser();
    try {
      await logout();
      router.push('/auth/login/');
    } catch {
      showToast({
        status: 'error',
        headline: 'Error',
        message: TOAST_CLIENT_ERROR_MESSAGE,
      });
    }
  };
  const userAccountInformation = [
    {
      title: orderTitleLabel,
      href: '/account/order-history',
      infoList: [`${orderCount} orders`],
      onInfoClick: () => reporter.withPrefix('').tag(clickOrderHistory()),
    },
    {
      title: passwordTitleLabel,
      href: passwordPageUrl,
      infoList: passwordInfo,
      onInfoClick: () => reporter.tag(clickEditPassword()),
    },
    {
      title: addressTitleLabel,
      href: addressPageUrl,
      infoList: deliveryInfo,
      onInfoClick: () => reporter.tag(clickEditDelivery()),
    },
    {
      title: paymentTitleLabel,
      href: paymentPageUrl,
      infoList: paymentInfo,
      onInfoClick: () => reporter.tag(clickEditPayment()),
    },
  ].filter(info => {
    if (info.title === orderTitleLabel) {
      if (showOrderHistory) return true;
      return false;
    }
    return true;
  });

  const accountSubscriptionsInformation = {
    title: subscriptionsTitleLabel,
    href: subscriptionsPageUrl,
    subscriptions: subscriptionsInfo,
    onSubscriptionsClick: () => reporter.tag(clickEditSubscriptions()),
  };

  return (
    <StyledInfoContainer>
      <AccountHeader
        links={LOGGEDIN_LINKS}
        logoUrl="/"
        logoSrText="Ollie logo"
        name={customer.first_name}
      />
      <Container>
        <HeadingContainer>
          <Heading
            headingLevel="h3"
            typography="heading1"
            headingText={headerTitleLabel}
            position="center"
          />
        </HeadingContainer>
        <EditMyAccountInfoForm
          userAccountInformation={userAccountInformation}
        />
        <AccountSubscriptionsContainer {...accountSubscriptionsInformation} />
        <LinkContainer>
          <ButtonLink variant="hero.cranberry" onClick={logOut}>
            Logout
          </ButtonLink>
        </LinkContainer>
      </Container>
      <Footer {...footer} />
    </StyledInfoContainer>
  );
};

MyInfoContainer.displayName = 'MyInfoContainer';
MyInfoContainer.propTypes = {
  paymentTitleLabel: PropTypes.string.isRequired,
  orderTitleLabel: PropTypes.string.isRequired,
  paymentPageUrl: PropTypes.string.isRequired,
  addressTitleLabel: PropTypes.string.isRequired,
  addressPageUrl: PropTypes.string.isRequired,
  passwordTitleLabel: PropTypes.string.isRequired,
  passwordPageUrl: PropTypes.string.isRequired,
  subscriptionsTitleLabel: PropTypes.string.isRequired,
  subscriptionsPageUrl: PropTypes.string.isRequired,
  headerTitleLabel: PropTypes.string.isRequired,
  footer: PropTypes.object.isRequired,
};
