'use client';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.spacing(8)};
  max-width: 512px;
  text-align: center;

  @media ${props => props.theme.tablet} {
    margin-top: ${({ theme }) => theme.spacing(12)};
  }
`;

export const ButtonLinkContainer = styled.div`
  text-align: center;
`;
