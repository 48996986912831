'use client';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: grid;
  margin: ${({ theme }) => theme.spacing(8, 0)};
  margin-left: auto;
  margin-right: auto;
  max-width: 512px;
  text-align: center;
  gap: ${({ theme }) => theme.spacing(4)};

  @media ${props => props.theme.tablet} {
    margin-top: ${({ theme }) => theme.spacing(10)};
  }
  button {
    width: 100%;
  }
`;

export const NextShippingContainer = styled.div`
  margin-top: ${props => props.theme.spacing(8)};
`;

export const Subheading = styled.p`
  ${props =>
    props.subheading
      ? props.theme.typography.heading4
      : props.theme.typography.body}
  margin-bottom: ${props => props.theme.spacing(1)};
`;

export const StyledDatePickerForm = styled.div`
  margin: auto;
  display: grid;
  gap: ${props => props.theme.spacing(8)};
  padding: ${props => props.theme.spacing(8)};
  text-align: center;
  @media ${props => props.theme.tablet} {
    padding: 0px;
    gap: ${props => props.theme.spacing(6)};
  }
`;

export const WarningText = styled.p`
  ${({ theme }) => theme.fontFamily.base}
  color: ${({ theme }) => theme.colors.status.warningDark};
  padding-top: ${({ theme }) => theme.spacing(2)};
`;
export const MaxDateReachedContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: start;
  align-content: space-between;

  @media ${props => props.theme.tablet} {
    max-width: 450px;
  }
`;
export const BoxLockedInText = styled.p`
  ${({ theme }) => theme.fontFamily.base}
  color: ${({ theme }) => theme.colors.status.warningDark};
  line-height: 24px;
  text-align: center;
  padding: ${({ theme }) => theme.spacing(3)};
`;
export const MaxDateReachedText = styled.p`
  ${({ theme }) => theme.fontFamily.base}
  color: ${({ theme }) => theme.colors.status.warningDark};
  padding-left: ${({ theme }) => theme.spacing(3)};
  line-height: 24px;
  text-align: left;
`;
