'use client';
import styled, { css } from 'styled-components';

export const DividerContainer = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing(2, 12, 0, 10)};
  display: inline-block;
  @media ${props => props.theme.laptop} {
    ${props =>
      props.$direction === 'vertical'
        ? css`
            width: auto;
            padding: ${props => props.theme.spacing(0, 12, 0, 10)};
          `
        : ''};
  }
`;

export const InnerDivider = styled.div`
  width: 100%;
  position: relative;
  opacity: 0.25;
  height: 1px;
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.colors.text.tertiary};

  @media ${props => props.theme.laptop} {
    ${props =>
      props.$direction === 'vertical'
        ? css`
            border-bottom: none;
            border-left: 1px solid ${props => props.theme.colors.text.tertiary};
            width: 1px;
            top: 10%;
            height: 80%;
          `
        : ''};
  }
`;
