export const onInfoSectionClicked = (section, reactivationMode) => {
  let type = 'Plan Summary - Section Clicked';
  if (reactivationMode) {
    type = 'Plan Summary - Reactivation - Section Clicked';
  }
  return {
    type,
    section,
  };
};

export const onPlanSummaryError = errorMessage => ({
  type: 'Plan Summary - Error',
  errorMessage,
});

export const onPlanSummaryCancel = () => ({
  type: 'Plan Summary - Cancel',
});
