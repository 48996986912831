export const boxShippingSoonInitiated = () => ({
  type: `View Too Late To Change Delivery Date page`,
});

export const boxShippingSoonBackDashboard = () => ({
  type: `Click back to dashboard`,
});

export const userClicksUpdateNextBoxButton = boxDeliveryDate => ({
  type: 'Clicks Update Next Box',
  data: {
    box_delivery_date: boxDeliveryDate,
  },
});
