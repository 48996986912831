'use client';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { addDays, differenceInDays, format, parseISO } from 'date-fns';
import { Footer, LoadingView } from '@web/components';
import { Heading, SmolLink } from '@web/atoms';
import { useReporter } from '../../reporter';
import { useSubscription } from '../../subscriptions';
import { useDeliveryDatesByZip } from '../../orders';
import { useCurrentPet } from '../useCurrentPet';
import { RedirectToLogin } from '../../router';
import { ApiResult } from '../../api';
import { DatePickerForm } from '../DatePickerForm';
import { useToast, TOAST_CLIENT_ERROR_MESSAGE } from '../../Toast';
import { useAddress, useSessionCustomer } from '../../customer';
import { AccountHeader } from '../AccountHeader';
import { CUSTOM_DATE, ERROR_MESSAGE } from '../constants';
import {
  userUpdatesDeliveryDate,
  userViewsChangeDeliveryDatePage,
  userClicksOnChangeFrequency,
} from './events';
import { LinkContainer, FormWrapper } from './styles';

export const DatePickerContainer = ({
  datePickerForm,
  labelButtonChangeFrequency,
  changeFrequencyButtonTarget,
  links,
  logoUrl,
  logoSrText,
  title,
  asapWarning,
  maxDateReachedWarning,
  boxLockedInWarning,
  footer,
}) => {
  const reporter = useReporter();
  const { showToast } = useToast();
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { pet, isLoading: isPetLoading } = useCurrentPet();
  const {
    nextDeliveryDate,
    updateSubscription,
    subscription,
    isLoading: isSubscriptionLoading,
  } = useSubscription(pet?.id);

  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();
  const { address, isLoading: isAddressLoading } = useAddress(customer?.id);

  const orderIsLockedIn = subscription?.is_locked_in;
  //adding 6 days to start date query will avoid BE logic that cancels orders to avoid duplicates
  //from being created - this BE logic will be removed in the future
  const startDateForFollowingBox = orderIsLockedIn
    ? format(addDays(nextDeliveryDate, 6), 'yyyy-MM-dd')
    : undefined;
  const { disabledDates, minDate, maxDate } = useDeliveryDatesByZip({
    customerId: customer?.id,
    zipCode: address?.zip_code,
    transitOption: subscription?.transit_option_id,
    startDate: orderIsLockedIn ? startDateForFollowingBox : undefined,
    commercial: !address?.is_residential ? true : undefined,
  });

  //if the order is locked in, display the target delivery date for the following box
  const defaultValues = {
    next_delivery_target: orderIsLockedIn
      ? parseISO(subscription?.next_delivery_target)
      : nextDeliveryDate,
  };

  const isLoading =
    isSubscriptionLoading ||
    isCustomerLoading ||
    isPetLoading ||
    isAddressLoading;

  useEffect(() => {
    if (!isLoading)
      reporter.tag(
        userViewsChangeDeliveryDatePage({
          boxState: orderIsLockedIn,
          boxDeliveryDate: subscription?.next_delivery_target,
          selectionType: CUSTOM_DATE,
        }),
      );
  }, [
    reporter,
    isLoading,
    orderIsLockedIn,
    subscription?.next_delivery_target,
  ]);

  if (!isActive && !isCustomerLoading) {
    return <RedirectToLogin />;
  }

  if (isLoading) {
    return <LoadingView />;
  }

  const nextDeliveryLabel = `Next delivery for ${pet.name}`;
  const nextDeliveryDateFormatted = orderIsLockedIn
    ? format(
        parseISO(subscription?.next_delivery_target),
        'EEEE, MMMM do, yyyy',
      )
    : format(nextDeliveryDate, 'EEEE, MMMM do, yyyy');
  // expected formatting for analytics is yyyy-MM-dd
  const originalDeliveryDate = orderIsLockedIn
    ? subscription?.next_delivery_target
    : format(nextDeliveryDate, 'yyyy-MM-dd');
  // getDaysChanged requires an ISO date format for calculation
  const nextDeliveryDateISO = orderIsLockedIn
    ? parseISO(subscription?.next_delivery_target)
    : nextDeliveryDate;

  const getDaysChanged = newNextDeliveryDate =>
    differenceInDays(newNextDeliveryDate, nextDeliveryDateISO);

  const onSubmit = async form => {
    setIsSubmitting(true);
    const result = await updateSubscription({
      next_delivery_target: format(form.next_delivery_target, 'yyyy-MM-dd'),
    });
    ApiResult.match(result, {
      success: () => {
        reporter.tag(
          userUpdatesDeliveryDate({
            boxEditing: orderIsLockedIn ? 'next' : 'current',
            daysChanged: getDaysChanged(form.next_delivery_target),
            originalDeliveryDate,
            newDeliveryDate: format(form.next_delivery_target, 'yyyy-MM-dd'),
            selectionType: CUSTOM_DATE,
            changeType: CUSTOM_DATE,
          }),
        );
        router.push('/account');
        showToast({
          status: 'success',
          message: 'Your delivery date has been successfully updated.',
          headline: 'Success',
        });
      },
      error: {
        server: () => {
          showToast({
            status: 'error',
            message: ERROR_MESSAGE,
            headline: 'Error Message',
          });
        },
        client: error => {
          reporter.error(error.original);
          showToast({
            status: 'error',
            message: TOAST_CLIENT_ERROR_MESSAGE,
            headline: 'Error Message',
          });
        },
      },
    });
    ApiResult.ifError(result, () => setTimeout(() => setIsSubmitting(false)));
  };

  const onChangeFrequencyClick = () => {
    reporter.tag(userClicksOnChangeFrequency());
  };

  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <FormWrapper>
        <Heading
          headingLevel="h1"
          typography="heading1"
          headingText={title}
          position="center"
        />
        <DatePickerForm
          {...datePickerForm}
          onSubmit={onSubmit}
          disabledDates={disabledDates}
          minDate={minDate}
          maxDate={maxDate}
          defaultValues={defaultValues}
          nextDeliveryLabel={nextDeliveryLabel}
          nextDeliveryDate={nextDeliveryDateFormatted}
          isSubmitting={isSubmitting}
          asapWarning={asapWarning}
          maxDateReachedWarning={maxDateReachedWarning}
          boxLockedInWarning={boxLockedInWarning}
          orderIsLockedIn={orderIsLockedIn}
        />
        <LinkContainer>
          <SmolLink
            href={{
              pathname: changeFrequencyButtonTarget,
              query: { pet_id: pet.id },
            }}
            onClick={onChangeFrequencyClick}
          >
            {labelButtonChangeFrequency}
          </SmolLink>
        </LinkContainer>
      </FormWrapper>
      <Footer {...footer} />
    </>
  );
};

DatePickerContainer.displayName = 'DatePickerContainer';

DatePickerContainer.propTypes = {
  title: PropTypes.string.isRequired,
  datePickerForm: PropTypes.object.isRequired,
  labelButtonChangeFrequency: PropTypes.string.isRequired,
  changeFrequencyButtonTarget: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  asapWarning: PropTypes.string.isRequired,
  maxDateReachedWarning: PropTypes.string.isRequired,
  boxLockedInWarning: PropTypes.string.isRequired,
  footer: PropTypes.object.isRequired,
};
