'use client';
import styled from 'styled-components';

export const StyledFrequencyContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.base};
`;

export const Content = styled.div`
  max-width: 1040px;
  margin: auto;
  padding: ${({ theme }) => theme.spacing(12, 6, 22)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(14, 0, 36)};
  }
`;

export const SubtitleContainer = styled.div`
  @media ${props => props.theme.tablet} {
    margin-bottom: ${props => props.theme.spacing(12)};
  }
`;

export const Subtitle = styled.p`
  ${props => props.theme.typography.body}
  margin: ${props => props.theme.spacing(4, 0, 8)};
  text-align: center;

  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(4, 40, 8)};
  }
`;
export const GFWarningText = styled.p`
  color: ${({ theme }) => theme.colors.status.errorPrimary};
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.14px;
  margin: ${props => props.theme.spacing(8)};
`;
