export const props = {
  customerId: 1,
  customerEmail: 'padawan@myollie.com',
  petSubscriptionId: '2',
  addOns: false,
  accountCreationDate: '2022-05-09T21:04:00.994656Z',
  mealPrepType: 'MX',
  planFrequency: 4,
  lastOrderSubtotal: '50',
  nextDeliveryDate: '2022-10-10',
  hasGFPlan: false,
};
export const GF_CANCELLATION_TEXT =
  'Your current price plan is no longer available to new members. Canceling your membership forfeits this plan and subjects you to increased pricing should you reactivate.';
