'use client';
import styled from 'styled-components';

export const StyledQuickAddExtrasContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: ${({ theme }) => theme.spacing(2)};

  @media ${({ theme }) => theme.tablet} {
    margin-top: ${({ theme }) => theme.spacing(5)};
  }
`;

export const Extra = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 200px;
  width: 100%;
  min-height: 200px;
  padding: 0 8px;

  @media ${({ theme }) => theme.tablet} {
    max-width: 300px;
    padding: 0;
  }
`;

export const ExtrasContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.lightest};
  border-radius: ${({ theme }) => theme.spacing(8)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: ${({ theme }) => theme.spacing(6, 4)};

  ${Extra} {
    &:nth-of-type(3) {
      display: none;
    }
  }

  @media ${({ theme }) => theme.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: ${({ theme }) => theme.spacing(8)};
    padding: ${({ theme }) => theme.spacing(8, 10, 8, 13)};

    ${Extra} {
      &:nth-of-type(3) {
        display: grid;
      }
    }
  }
`;

export const ExtraInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.tablet} {
    flex-direction: row;
  }
`;

export const ExtraInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;

  @media ${({ theme }) => theme.tablet} {
    padding-left: ${({ theme }) => theme.spacing(4)};
  }
`;

export const ProductName = styled.div`
  ${({ theme }) => theme.typography.body}
  ${({ theme }) => theme.fontFamily.base}
  font-weight: bold;
`;

export const Size = styled.p`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const Price = styled.span`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.status.successPrimary};
  margin-right: ${({ theme }) => theme.spacing(1)};
  font-weight: bold;
`;

export const ExtraImage = styled.div`
  position: relative;
  object-fit: fit;
  width: 100%;
  cursor: pointer;

  img {
    width: 100%;
    border-radius: ${({ theme }) => theme.spacing(8)};
    margin: 0;
  }
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  z-index: 1;
  border: 1px solid white;
  border-radius: ${({ theme }) => theme.spacing(4)};
`;

export const AddButtonContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(4)};
  align-self: flex-end;
  button {
    width: 100%;
    height: 48px;

    @media ${({ theme }) => theme.mobileL} {
      height: auto;
    }
  }
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  @media ${({ theme }) => theme.mobileL} {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export const LinkContainer = styled.div`
  line-height: ${({ theme }) => theme.spacing(10)};

  font-weight: bold;
  @media ${({ theme }) => theme.mobileL} {
    line-height: ${({ theme }) => theme.spacing(10)};
  }
`;

export const LoadingSpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.background.lightest};
  opacity: 0.9;
  border-radius: ${({ theme }) => theme.spacing(8)};
  div {
    position: absolute;
    left: 50%;
    top: 45%;
    width: 40px;
    height: 40px;
    border-top: 4px solid ${({ theme }) => theme.colors.secondary.chia};
  }
`;
