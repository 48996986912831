'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const StyledBoxSummaryExtras = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  border: 1px solid
    ${props =>
      props.errorBorder
        ? props.theme.colors.status.errorPrimary
        : props.theme.transparent};
  padding: ${props => props.theme.spacing(6, 7, 6, 10)};
  background: ${props => props.theme.colors.background.lightest};
  margin-top: ${props => props.theme.spacing(8)};
  border-radius: ${props => props.theme.spacing(8)};
  width: 100%;
  @media ${props => props.theme.tablet} {
    display: grid;
    grid-row: auto auto;
    grid-column: auto auto;
  }
`;

export const RecipeInfoSection = styled.div`
  padding: ${props => props.theme.spacing(2, 0)};
`;

export const ExtrasTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ExtrasInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: rowl;
  justify-content: space-between;
`;

export const Top = styled.div``;
export const HrTag = styled.hr`
  border: none;
  height: 1px;
  background-color: ${({ theme }) =>
    theme.colors.withAlpha('border.fur', 0.25)};
`;

export const SummaryInfo = styled.div``;

export const FlexRowContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: ${props => props.theme.spacing(6)};
  flex-direction: row;
  padding-bottom: ${props => props.theme.spacing(2)};
`;

export const Headline = styled.h2`
  ${props => props.theme.typography.subheading1};
  width: 100%;
`;

export const Discount = styled.div`
  margin: ${props => props.theme.spacing(1, 0, 2, 0)};
`;

export const ButtonContainer = styled.div`
  max-width: 200px;
`;

export const ChargeText = styled.p`
  text-align: center;
  ${props => props.theme.typography.body};
  padding-bottom: ${props => props.theme.spacing(3)};
`;

export const RecipesInfoContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const EtrasInfoContainer = styled.p`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const SelectedRecipes = styled.span`
  ${props => props.theme.typography.body};
`;

export const MealPrepTypeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const MealPrepTypeLabel = styled.span`
  ${props => props.theme.typography.bodyBold};
  padding-right: ${props => props.theme.spacing(2)};
`;

export const Checkout = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(2)};
  flex: 1 0 auto;
  align-items: center;
  width: 100%;
  margin-top: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(3)};
`;

export const CranberryText = styled.p`
  ${props => props.theme.typography.bodyBold};
  padding-right: ${props => props.theme.spacing(2)};
  color: ${props => props.theme.colors.hero.cranberry};
`;

export const CranberryTextSpan = styled.span`
  color: ${props => props.theme.colors.hero.cranberry};
  text-decoration: line-through;
`;

export const ImageContainer = styled.div`
  width: 30%;
  margin-right: 15px;
  @media ${props => props.theme.tablet} {
    width: 15%;
    margin-right: 5px;
  }
`;

export const ErrorLink = styled(Link)`
  color: ${props => props.theme.colors.status.errorPrimary};
  text-decoration: underline;
`;
