import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import {
  CardWrapper,
  Label,
  Body,
  Info,
  Input,
  ImageContainer,
  Footer,
} from './styles';

export const RadioCard = ({
  id,
  title,
  fontColor = 'text.secondary',
  body,
  onChange,
  active,
  ariaControls,
  children = undefined,
  footer = '',
  footerColor = 'text.secondary',
  image = undefined,
  textAlign = '',
}) => {
  return (
    <CardWrapper
      htmlFor={id}
      active={active}
      role="tab"
      aria-selected={active}
      aria-controls={ariaControls}
      aria-expanded={active}
    >
      {children && children}
      {image && (
        <ImageContainer>
          <Image
            src={image.src}
            alt={image.alt}
            height={image.height}
            width={image.width}
          />
        </ImageContainer>
      )}
      <Info $textAlign={textAlign}>
        <Input
          type="radio"
          name={title}
          id={id}
          value={title}
          checked={active}
          onChange={() => onChange(id)}
        />
        <Label htmlFor={id}>{title}</Label>
        <Body $fontColor={fontColor}>{body}</Body>
        {footer && <Footer $footerColor={footerColor}>{footer}</Footer>}
      </Info>
    </CardWrapper>
  );
};

RadioCard.displayName = 'RadioCard';

RadioCard.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fontColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ariaControls: PropTypes.string, // new prop to link to the content area
  children: PropTypes.node,
  image: PropTypes.object,
  footer: PropTypes.string,
  textAlign: PropTypes.string,
};
