import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { Icon } from '@ui/icons';
import { useReporter } from '../../reporter';
import { userClicksDashboardEditExtras } from './events';
import {
  Container,
  Title,
  TitleContainer,
  InfoListContainer,
  InfoLine,
  IconContainer,
  Anchor,
  DoubleInfoLinkContainer,
  InfoLineBold,
  InfoContainer,
  HrTag,
  InfoLineNoExtras,
  MoreExtras,
  SubscriptionExtra,
} from './styles.js';

export const NavLink = ({ addonType, href, addons, onInfoClick, id }) => {
  const reporter = useReporter();
  const currentAddons = addons[addonType];
  if (currentAddons.length === 0) return null;
  return (
    <Anchor
      href={href}
      onClick={() => reporter.tag(userClicksDashboardEditExtras())}
    >
      <HrTag />
      <DoubleInfoLinkContainer onClick={onInfoClick}>
        <InfoContainer>
          <InfoLine>{capitalize(addonType)}</InfoLine>
          <InfoLineBold>
            {currentAddons.length < 5
              ? currentAddons.map((addon, idx) => (
                  <SubscriptionExtra key={idx}>
                    {capitalize(addon)}
                  </SubscriptionExtra>
                ))
              : [
                  ...currentAddons.slice(0, 4),
                  `+ ${currentAddons.length - 4} more`,
                ].map((addon, idx) => {
                  if (idx === 4)
                    return <MoreExtras key={idx}>{addon}</MoreExtras>;
                  return (
                    <SubscriptionExtra key={idx}>
                      {capitalize(addon)}
                    </SubscriptionExtra>
                  );
                })}
          </InfoLineBold>
        </InfoContainer>
        <IconContainer>
          <Icon
            iconName="chevron-right"
            alt="chevron right icon"
            color="text.secondary"
            size="22"
            id={`nav_link_${id}`}
          />
        </IconContainer>
      </DoubleInfoLinkContainer>
    </Anchor>
  );
};

NavLink.displayName = 'NavLink';

NavLink.propTypes = {
  addonType: PropTypes.string.isRequired,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  addons: PropTypes.shape({
    treats: PropTypes.arrayOf(PropTypes.string.isRequired),
    supplements: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  onInfoClick: PropTypes.func,
  id: PropTypes.string.isRequired,
};

export const NavLinkNoExtras = ({ href, onInfoClick }) => {
  const reporter = useReporter();
  const handleClick = () => {
    onInfoClick();
    reporter.tag(userClicksDashboardEditExtras());
  };
  return (
    <Anchor href={href} onClick={handleClick}>
      <HrTag />
      <DoubleInfoLinkContainer>
        <InfoContainer>
          <InfoLineNoExtras>Explore Extras Now</InfoLineNoExtras>
        </InfoContainer>
        <IconContainer>
          <Icon
            iconName="chevron-right"
            alt="chevron right icon"
            color="text.secondary"
            size="22"
          />
        </IconContainer>
      </DoubleInfoLinkContainer>
    </Anchor>
  );
};

NavLinkNoExtras.displayName = 'NavLinkNoExtras';

NavLink.propTypes = {
  href: PropTypes.object.isRequired,
  onInfoClick: PropTypes.func,
};

export const AddonsNavLink = ({ title, onInfoClick, navLinks, addons }) => (
  <Container>
    <InfoListContainer>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      {addons.treats.length || addons.supplements.length ? (
        navLinks.map(navLink => (
          <NavLink
            key={navLink.addonType}
            {...navLink}
            addons={addons}
            onInfoClick={onInfoClick}
            id={navLink.addonType}
          />
        ))
      ) : (
        <NavLinkNoExtras href={navLinks[0].href} onInfoClick={onInfoClick} />
      )}
    </InfoListContainer>
  </Container>
);

AddonsNavLink.displayName = 'AddonsNavLink';

AddonsNavLink.propTypes = {
  title: PropTypes.string.isRequired,
  onInfoClick: PropTypes.func,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      addonType: PropTypes.string.isRequired,
      href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }).isRequired,
  ).isRequired,
  addons: PropTypes.shape({
    treats: PropTypes.arrayOf(PropTypes.string),
    supplements: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
