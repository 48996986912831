'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const StyledBoxShippingSoon = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(8)};
  background-color: ${({ theme }) => theme.colors.background.lightest};
  border-radius: 32px;
  padding: ${({ theme }) => theme.spacing(8, 6)};

  @media ${({ theme }) => theme.tablet} {
    padding: ${({ theme }) => theme.spacing(12, 22, 14)};
  }
`;

export const ImageContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(8)} auto;
  max-width: ${({ theme }) => theme.spacing(40)};
  width: 160px;
  height: 115px;

  img {
    width: 100%;
  }

  @media ${({ theme }) => theme.tablet} {
    max-width: ${({ theme }) => theme.spacing(56)};
    width: 228px;
    height: 162px;
  }
`;

export const WarningText = styled.span`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.status.warningDark};
  text-align: center;
`;

export const DateText = styled.span`
  ${({ theme }) => theme.typography.heading3}
  color: ${({ theme }) => theme.colors.text.primary};
  text-align: center;
`;

export const InfoText = styled.span`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: center;
`;

export const DateContainer = styled.div`
  display: grid;
`;

export const DashboardLink = styled(Link)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  color: ${({ theme }) => theme.colors.secondary.chia};
  text-decoration: underline;
`;

export const NavLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
`;
