'use client';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { interpolate } from '@format/interpolate';
import { AddPupContainer } from '../AddPupContainer';
import { AddPupPlanSummaryContainer } from '../AddPupPlanSummaryContainer';
import { AddPupSuccessContainer } from '../AddPupSuccessContainer';
import { MealPrepTypeSelectionForm } from '../MealPrepTypeSelectionForm';
import { FreshRecipesSelectionForm } from '../FreshRecipesSelectionForm';
import { DryRecipesSelectionForm } from '../DryRecipesSelectionForm';
import { EditAddressContainer } from '../EditAddressContainer';
import { EditPaymentContainer } from '../EditPaymentContainer';
import {
  getVariantsSortedByPrice,
  getPriceAndMessageFromVariant,
  getStarterBoxDryConfig,
  getStarterBoxFreshConfig,
  getStarterBoxProductTypeConfig,
  getVariantByRecipes,
} from '../../products';
import {
  HIDE_BAKED_ONLY_MEMBER_EXPERIENCE,
  SHOW_HALF_FRESH_MEMBER_EXPERIENCE_ADD_PUP_AND_REACTIVATION_FLOWS,
  SHOW_LAMB,
  useExperiment,
} from '../../experiments';
import { StripeContext } from '../../payment';
import {
  EMPTY_PET,
  getPronoun,
  getActivity,
  getAllergies,
  PRONOUN_TYPES,
} from '../../pets';
import {
  productTypeReference,
  PRODUCT_TYPE_MIXED,
  PRODUCT_TYPE_DRY,
  PRODUCT_TYPE_HALF_FRESH,
} from '../../products/constants';
import { STATE, ACTIONS } from './constants';

const AddPupQuiz = ({ labels, petForm, lastStep, setPetFlow, send }) => {
  return (
    <AddPupContainer
      {...interpolate(labels, {})}
      cancelUrl={lastStep ? `/account/add-pup/${lastStep}` : labels.cancelUrl}
      defaultValues={petForm ?? EMPTY_PET}
      onCancel={() => {
        setPetFlow(null, { skipRender: true });
      }}
      onPetSave={pet => send(ACTIONS.submitPupForm({ pet }))}
    />
  );
};

AddPupQuiz.propTypes = {
  labels: PropTypes.object.isRequired,
  petForm: PropTypes.object,
  setPetFlow: PropTypes.func.isRequired,
  lastStep: PropTypes.string,
  send: PropTypes.func.isRequired,
};

const MealPrep = ({
  planSelectionInfo,
  labels,
  selectedPlanType,
  productTypes,
  petName,
  lastStep,
  send,
}) => {
  const { value: showHalfFresh } = useExperiment(
    SHOW_HALF_FRESH_MEMBER_EXPERIENCE_ADD_PUP_AND_REACTIVATION_FLOWS,
    false,
  );
  const { value: hideBaked } = useExperiment(
    HIDE_BAKED_ONLY_MEMBER_EXPERIENCE,
    true,
  );

  let { items: filterMealPlans } = planSelectionInfo;

  if (!showHalfFresh)
    filterMealPlans = filterMealPlans.filter(
      plan => plan.value !== productTypeReference[PRODUCT_TYPE_HALF_FRESH],
    );
  if (hideBaked)
    filterMealPlans = filterMealPlans.filter(
      plan => plan.value !== productTypeReference[PRODUCT_TYPE_DRY],
    );

  const { defaultProductType, unavailableProductTypes } =
    getStarterBoxProductTypeConfig(productTypes, null, selectedPlanType);

  const freshPricePerWeek = !!productTypes.fresh.products.length
    ? getVariantsSortedByPrice(
        productTypes.fresh.products.flatMap(p => p.variants),
      )[0].price_per_week
    : '';
  const halfFreshPricePerWeek = !!productTypes.half_fresh.products.length
    ? getVariantsSortedByPrice(
        productTypes.half_fresh.products.flatMap(p => p.variants),
      )[0].price_per_week
    : '';
  const mixedPricePerWeek = !!productTypes.mixed.products.length
    ? getVariantsSortedByPrice(
        productTypes.mixed.products.flatMap(p => p.variants),
      )[0].price_per_week
    : '';
  const bakedPricePerWeek = !!productTypes.dry.products.length
    ? getVariantsSortedByPrice(
        productTypes.dry.products.flatMap(p => p.variants),
      )[0].price_per_week
    : '';
  const onCancelAction = () => send(ACTIONS.toSummary());

  return (
    <MealPrepTypeSelectionForm
      {...interpolate(
        { ...planSelectionInfo, mealPrepTypes: filterMealPlans },
        {
          pupName: petName,
          freshDiscountPricePerWeek: '',
          mixedDiscountPricePerWeek: '',
          bakedDiscountPricePerWeek: '',
          halfFreshDiscountPricePerWeek: '',
          mixedPricePerWeek,
          freshPricePerWeek,
          bakedPricePerWeek,
          halfFreshPricePerWeek,
        },
      )}
      links={labels.links}
      submitButtonText={planSelectionInfo.nextStepText}
      cancelButtonText={labels.cancelButtonText}
      cancelTarget={
        lastStep ? `/account/add-pup/${lastStep}` : labels.cancelTarget
      }
      cancelAction={lastStep ? onCancelAction : null}
      defaultMealPrepType={defaultProductType}
      unavailableMealPrepTypes={unavailableProductTypes}
      onSubmit={mealPrepType =>
        send(ACTIONS.selectedMealPrep({ mealPrepType }))
      }
    />
  );
};

MealPrep.propTypes = {
  planSelectionInfo: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  productTypes: PropTypes.object.isRequired,
  selectedPlanType: PropTypes.string,
  petName: PropTypes.string.isRequired,
  lastStep: PropTypes.string,
  send: PropTypes.func.isRequired,
};

const Fresh = ({
  labels,
  productTypes,
  selectedPlanType,
  petName,
  petForm,
  lastStep,
  send,
  planSelectionInfo,
}) => {
  const { value: showLamb } = useExperiment(SHOW_LAMB, true);
  const { defaultRecipes, product, unavailableRecipes } =
    getStarterBoxFreshConfig(productTypes[selectedPlanType], null, null);
  if (!showLamb) unavailableRecipes.push('lamb');
  const getConfigFromRecipes = selectedRecipes => {
    const variants = getVariantsSortedByPrice(product.variants);
    const newVariant = getVariantByRecipes(variants, {
      fresh: selectedRecipes,
      dry: product.variants[0].recipes.dry,
    });
    const defaultVariantPrice = parseFloat(
      variants[0].price_per_week ?? product.variants[0].price_per_week,
    );
    const { price, message } = getPriceAndMessageFromVariant(
      newVariant,
      defaultVariantPrice,
      selectedRecipes,
    );
    return {
      price,
      message,
    };
  };

  const onNoRecipesFoundClick = () => send(ACTIONS.toAddPupQuiz());

  const onCancelAction = () => send(ACTIONS.toSummary());

  const onSubmit = recipes => send(ACTIONS.selectedFreshRecipes({ recipes }));

  const { nextStepText, headline, subheadline, modals, items } =
    planSelectionInfo;

  return (
    <FreshRecipesSelectionForm
      {...labels}
      headline={interpolate(headline, { pupName: petName })}
      subheadline={interpolate(subheadline, {
        pupName: petName,
        pupWeight: petForm.weight,
        pupActivity: getActivity(petForm.activity),
        pupBreed: petForm.breeds?.map(breed => breed.name || breed.label),
      })}
      recipes={items}
      cancelTarget={
        lastStep ? `/account/add-pup/${lastStep}/` : labels.cancelTarget
      }
      submitButtonText={nextStepText}
      cancelAction={lastStep ? onCancelAction : null}
      defaultRecipes={defaultRecipes}
      unavailableRecipes={unavailableRecipes}
      onNoRecipesFoundClick={onNoRecipesFoundClick}
      onSubmit={onSubmit}
      getConfigFromRecipes={getConfigFromRecipes}
      modals={modals}
    />
  );
};

Fresh.propTypes = {
  labels: PropTypes.object.isRequired,
  productTypes: PropTypes.object.isRequired,
  selectedPlanType: PropTypes.string.isRequired,
  selectedFreshRecipes: PropTypes.array,
  petName: PropTypes.string.isRequired,
  petForm: PropTypes.object.isRequired,
  lastStep: PropTypes.string,
  send: PropTypes.func.isRequired,
  planSelectionInfo: PropTypes.object.isRequired,
};

const Baked = ({
  labels,
  productTypes,
  selectedPlanType,
  selectedFreshRecipes,
  petName,
  lastStep,
  petForm,
  send,
  planSelectionInfo,
}) => {
  const { defaultRecipes, product, unavailableRecipes } =
    getStarterBoxDryConfig(productTypes[selectedPlanType], null, null);

  const getConfigFromRecipes = selectedRecipes => {
    const variants = getVariantsSortedByPrice(product.variants);
    const newVariant = getVariantByRecipes(variants, {
      dry: selectedRecipes,
      fresh: selectedFreshRecipes || product.variants[0].recipes.fresh,
    });
    const defaultVariantPrice = parseFloat(
      variants[0].price_per_week ?? product.variants[0].price_per_week,
    );
    const { price, message } = getPriceAndMessageFromVariant(
      newVariant,
      defaultVariantPrice,
      defaultRecipes,
    );
    return {
      price,
      message,
    };
  };

  const onCancelAction = () => send(ACTIONS.toSummary());

  const onSubmit = recipes => send(ACTIONS.selectedBakedRecipes({ recipes }));

  const { nextStepText, headline, subheadline, modals, items } =
    planSelectionInfo;

  return (
    <DryRecipesSelectionForm
      {...labels}
      headline={interpolate(headline, { pupName: petName })}
      subheadline={interpolate(subheadline, {
        pupWeight: petForm.weight,
        pupActivity: getActivity(petForm.activity),
        pupBreed: petForm.breeds?.map(breed => breed.name || breed.label),
        pupAllergies: getAllergies(petForm.allergies),
        pupPronoun: getPronoun(petForm.gender, {
          type: PRONOUN_TYPES.POSSESSIVE_ADJECTIVE,
          perspective: 3,
        }),
      })}
      cancelTarget={
        lastStep ? `/account/add-pup/${lastStep}/` : labels.cancelTarget
      }
      submitButtonText={nextStepText}
      cancelAction={lastStep ? onCancelAction : null}
      defaultRecipes={defaultRecipes}
      unavailableRecipes={unavailableRecipes}
      onSubmit={onSubmit}
      getConfigFromRecipes={getConfigFromRecipes}
      recipes={items}
      modals={modals}
    />
  );
};

Baked.propTypes = {
  labels: PropTypes.object.isRequired,
  productTypes: PropTypes.object.isRequired,
  selectedPlanType: PropTypes.string.isRequired,
  selectedFreshRecipes: PropTypes.array,
  petName: PropTypes.string.isRequired,
  petForm: PropTypes.shape({
    activity: PropTypes.string.isRequired,
    weight: PropTypes.number.isRequired,
    breeds: PropTypes.array.isRequired,
    gender: PropTypes.string.isRequired,
    allergies: PropTypes.array.isRequired,
  }).isRequired,
  lastStep: PropTypes.string,
  send: PropTypes.func.isRequired,
  planSelectionInfo: PropTypes.object.isRequired,
};

const Summary = ({
  labels,
  petForm,
  petName,
  productTypes,
  selectedPlanType,
  selectedFreshRecipes,
  selectedBakedRecipes,
  setPetFlow,
  send,
}) => {
  const { starterBoxes, recommended } = productTypes[selectedPlanType];
  const variants = starterBoxes[0].variants;
  const getBakedRecipes =
    selectedPlanType === productTypeReference[PRODUCT_TYPE_MIXED] &&
    selectedBakedRecipes.length === 0
      ? recommended.recipes.dry
      : selectedBakedRecipes;
  const variant = getVariantByRecipes(variants, {
    fresh: selectedFreshRecipes,
    dry: getBakedRecipes,
  });
  const starterBoxWeeksOfFood = starterBoxes[0].weeks_of_food || 2;
  return (
    <AddPupPlanSummaryContainer
      {...interpolate(labels, { pupName: petName, pupCalories: 'TODO' })}
      petForm={petForm}
      starterBox={variant}
      weeksOfFood={starterBoxWeeksOfFood}
      productSku={variant.sku}
      subscriptionSku={variant.subscription_sku}
      onSubmit={petId => {
        setPetFlow(null, { skipRender: true });
        send(ACTIONS.toSuccess({ petId }));
      }}
      onCancel={() => setPetFlow(null, { skipRender: true })}
      pupInfoTarget={{
        pathname: `/account/add-pup/${STATE.quiz}`,
      }}
      mealPrepTarget={{
        pathname: `/account/add-pup/${STATE.mealprep}`,
      }}
      freshRecipesTarget={{
        pathname: `/account/add-pup/${STATE.fresh}`,
      }}
      bakedRecipesTarget={{
        pathname: `/account/add-pup/${STATE.baked}`,
        query: { edit: `${true}` },
      }}
      paymentTarget={{
        pathname: `/account/add-pup/${STATE.payment}`,
      }}
      deliveryTarget={{
        pathname: `/account/add-pup/${STATE.delivery}`,
      }}
    />
  );
};

Summary.propTypes = {
  labels: PropTypes.object.isRequired,
  petName: PropTypes.string.isRequired,
  petForm: PropTypes.object.isRequired,
  productTypes: PropTypes.object.isRequired,
  selectedPlanType: PropTypes.string.isRequired,
  selectedFreshRecipes: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedBakedRecipes: PropTypes.arrayOf(PropTypes.string).isRequired,
  setPetFlow: PropTypes.func.isRequired,
  lastStep: PropTypes.string,
  send: PropTypes.func.isRequired,
};

const Delivery = ({ labels, send }) => {
  return (
    <EditAddressContainer
      {...labels}
      onAddressChanged={() => send(ACTIONS.toSummary())}
    />
  );
};

Delivery.propTypes = {
  labels: PropTypes.object.isRequired,
  send: PropTypes.func.isRequired,
};

const Payment = ({ labels, send }) => {
  const { clientSetupSecret, stripePromise } = useContext(StripeContext);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorBackground: '#F4F4EC',
    },
    rules: {
      '.Input': {
        backgroundColor: '#FFF',
      },
      '.AccordionItem': {
        border: 'none',
        boxShadow: 'none',
      },
    },
  };

  const editOptions = {
    clientSecret: clientSetupSecret,
    appearance,
    fonts: [
      {
        // Local fonts will not load properly without https in development
        // src URLs need to start with 'https://' or 'data:'
        family: 'Founders Grotesk',
        src: `url(/fonts/founders-grotesk-web-regular.woff2) format('woff')`,
      },
    ],
  };

  return (
    clientSetupSecret && (
      <Elements stripe={stripePromise} options={editOptions}>
        <EditPaymentContainer
          {...labels}
          onPaymentChanged={() => send(ACTIONS.toSummary())}
        />
      </Elements>
    )
  );
};

Payment.propTypes = {
  labels: PropTypes.object.isRequired,
  send: PropTypes.func.isRequired,
};

const Success = ({ labels, petId }) => {
  return (
    <AddPupSuccessContainer
      {...labels}
      petId={petId}
      urlTarget={`/account/?pet_id=${petId}`}
    />
  );
};

Success.propTypes = {
  labels: PropTypes.object.isRequired,
  petId: PropTypes.string.isRequired,
};

export const components = {
  [STATE.quiz]: AddPupQuiz,
  [STATE.mealprep]: MealPrep,
  [STATE.fresh]: Fresh,
  [STATE.baked]: Baked,
  [STATE.summary]: Summary,
  [STATE.delivery]: Delivery,
  [STATE.payment]: Payment,
  [STATE.success]: Success,
};
