import * as yup from 'yup';
import { newPasswordSchema } from '../../auth';

export const currentPasswordSchema = yup.object().shape({
  current_password: yup
    .string()
    .trim()
    .required('Current password is required')
    .min(8, 'Passwords must be at least 8 characters long.'),
});

export const schema = newPasswordSchema.concat(currentPasswordSchema);
