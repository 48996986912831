'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, SocialButton } from '@web/components';
import { ResponsiveImage, List } from '@web/molecules';
import {
  PopupContainer,
  DesktopImageContainer,
  MobileImageContainer,
  ContentContainer,
  TitlesSectionContainer,
  StyledTitle,
  StyledSubtitle,
  QRSectionContainer,
  StyledQRExplanation,
  CTASectionContainer,
} from './styles';

export const AppCTAModal = ({ shown, onCloseModal, content }) => {
  const {
    desktopImage,
    mobileImage,
    title,
    subtitle,
    list,
    cta,
    qrCode,
    qrExplanation,
  } = content;

  return (
    <Modal
      ariaLabel="App call to action modal"
      closeBtnColor="neutral.white"
      mobilePopUp={true}
      shown={shown}
      onCloseModal={onCloseModal}
    >
      <PopupContainer>
        <MobileImageContainer>
          <ResponsiveImage {...mobileImage} width={400} />
        </MobileImageContainer>
        <DesktopImageContainer>
          <ResponsiveImage {...desktopImage} />
        </DesktopImageContainer>
        <ContentContainer>
          <TitlesSectionContainer>
            <StyledTitle>{title}</StyledTitle>
            <StyledSubtitle>{subtitle}</StyledSubtitle>
          </TitlesSectionContainer>
          <List
            items={list.items}
            icon={list.icon}
            iconColor="hero.spinach"
            gap={2}
          />
          {!!cta.length && (
            <CTASectionContainer>
              {cta.map((button, key) => (
                <SocialButton key={key} {...button} />
              ))}
            </CTASectionContainer>
          )}
          <QRSectionContainer>
            <StyledQRExplanation>{qrExplanation}</StyledQRExplanation>
            <ResponsiveImage {...qrCode} height={80} width={80} />
          </QRSectionContainer>
        </ContentContainer>
      </PopupContainer>
    </Modal>
  );
};

AppCTAModal.displayName = 'AppCTAModal';
AppCTAModal.propTypes = {
  shown: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
};
