'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinkButton } from '@ui/button';
import { Grid, Cell, Input, SmolLink } from '@web/atoms';
import { PasswordTooltip } from '@web/components';
import { ButtonContainer, ButtonLinkContainer } from './styles.js';
import { schema } from './schema';

const DEFAULT_VALUES = {
  current_password: '',
  new_password: '',
  confirm_password: '',
};

export const EditPasswordForm = ({
  currentPasswordLabel,
  newPasswordLabel,
  confirmPasswordLabel,
  saveChangesLabel,
  cancelLabel,
  cancelUrl,
  onSubmit,
  isSubmitting,
  forgotPasswordUrl,
  forgotPasswordLabel,
}) => {
  const { control, handleSubmit } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid columns={1} gap={{ row: 6 }}>
        <Cell>
          <Controller
            control={control}
            name="current_password"
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                type="password"
                labelText={currentPasswordLabel}
                status={error?.message && 'error'}
                error={error?.message}
              />
            )}
          />
        </Cell>
        <Cell>
          <Controller
            control={control}
            name="new_password"
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                type="password"
                labelText={newPasswordLabel}
                status={error?.message && 'error'}
                error={error?.message}
                icon={<PasswordTooltip />}
              />
            )}
          />
        </Cell>
        <Cell>
          <Controller
            control={control}
            name="confirm_password"
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                type="password"
                labelText={confirmPasswordLabel}
                status={error?.message && 'error'}
                error={error?.message}
              />
            )}
          />
        </Cell>
        <Cell>
          <ButtonLinkContainer>
            <SmolLink href={forgotPasswordUrl}>{forgotPasswordLabel}</SmolLink>
          </ButtonLinkContainer>
        </Cell>
        <Cell>
          <ButtonContainer>
            <Button disabled={isSubmitting} type="submit">
              {saveChangesLabel}
            </Button>
            <LinkButton href={cancelUrl} variant="secondary">
              {cancelLabel}
            </LinkButton>
          </ButtonContainer>
        </Cell>
      </Grid>
    </form>
  );
};
EditPasswordForm.displayName = 'EditPasswordForm';
EditPasswordForm.propTypes = {
  currentPasswordLabel: PropTypes.string.isRequired,
  newPasswordLabel: PropTypes.string.isRequired,
  confirmPasswordLabel: PropTypes.string.isRequired,
  saveChangesLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  forgotPasswordUrl: PropTypes.string.isRequired,
  forgotPasswordLabel: PropTypes.string.isRequired,
};
