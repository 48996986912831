'use client';
import styled from 'styled-components';

export const StyledEditPasswordContainer = styled.div`
  max-width: 512px;
  margin: auto;
  display: grid;
  gap: ${props => props.theme.spacing(8)};
  padding: ${props => props.theme.spacing(0, 6, 8)};

  @media ${props => props.theme.tablet} {
    gap: ${props => props.theme.spacing(6)};
  }
`;

export const HeadingContainer = styled.div`
  margin: ${props => props.theme.spacing(10, 0)};
  h1 {
    text-transform: capitalize;
  }
`;
