export const LABELS = {
  headerTitleLabel: 'Payment method',
  labelCardNumber: 'Card Number',
  labelCVC: 'CVV',
  labelExpirationDate: 'Exp Date',
  labelNameCard: 'Name on card',
  labelWithStripe: 'Pay securely with',
  saveBtnLabel: 'Save changes',
  cancelBtnLabel: 'Cancel',
  placeholderCVC: 'CVV',
  placeholderExpirationDate: 'MM/YY',
  placeholderCardNumber: '1234 1234 1234 1234',
  placeholderNameCard: 'Name on card',
};

export const paymentElementLabels = {
  cardNumber: 'Card number',
  expiration: 'Expiration',
  zip: 'ZIP',
  cvc: 'CVC',
};

export const NO_PAYMENT_TEXT =
  'You currently do not have a payment method added to your account. Add a payment method below';
