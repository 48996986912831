import { format, parseISO } from 'date-fns';
import { capitalize } from 'lodash';
import { interpolate } from '@format/interpolate';
import { ResponsiveImage } from '@web/molecules/ResponsiveImage';
import warningIcon from '../../images/Icons/warning-icon.svg';
import { ADDON_PRODUCT_TYPES_LIST } from '../../addons/constants';
import {
  PRODUCT_TYPE_FRESH,
  PRODUCT_TYPE_HALF_FRESH,
  FULL_PORTION,
  HALF_PORTION,
} from '../../products/constants';
import { PAYMENT_METHOD_IMAGES } from '../../payment/constants';
import {
  getActivity,
  getAge,
  getAllergies,
  getBreed,
  getNeutered,
} from '../../pets/functions';
import {
  getQuantityType,
  getRecommendedDentalChew,
  getUnavailableAddons,
} from '../../addons';
import {
  ADDON_PRODUCT_TYPES,
  DENTAL_CHEWS_SKUS,
  CRO_PET_BIRTH_DATE,
} from '../constants';
import { StyledInfoLineWrapper, NoPaymentMethodText } from './styles';

export const getDogs = pets =>
  pets ? pets.map(({ id, name }) => ({ value: id, label: name })) : [];

const getMealPrep = ({ product_type = null, portion_size = '1.0' } = {}) => {
  const mealPrep = {
    DY: 'dry',
    FR: 'fresh',
    MX: 'mixed',
  };

  if (product_type === PRODUCT_TYPE_FRESH && portion_size === HALF_PORTION) {
    return PRODUCT_TYPE_HALF_FRESH;
  }
  return mealPrep[product_type];
};

export const getNextBoxProps = ({
  subscription,
  nextOrderProduct,
  subscriptionProduct,
  pet,
  nextDeliveryDate,
  order,
  subAddonProducts,
}) => {
  const product = subscription.is_locked_in
    ? nextOrderProduct
    : subscriptionProduct;

  const nextBoxAddons = !!order
    ? order?.items.filter(item =>
        ADDON_PRODUCT_TYPES_LIST.includes(item.product_type),
      )
    : subAddonProducts?.filter(addon => addon.due_for_next_delivery);

  return {
    headline: `${pet.name}'s next box`,
    arrivalDate: format(nextDeliveryDate, 'MMM d'),
    cadence: product.weeks_of_food,
    recipes: {
      fresh: product.variants[0].recipes.fresh,
      dry: product.variants[0].recipes.dry,
    },
    totalPacks: Number(product.variants[0].tray_count),
    totalBags: Number(product.variants[0].bag_count),
    editFreshHref: {
      pathname: '/account/edit-recipes/recipes/fresh',
      query: {
        pet_id: pet.id,
        meal_prep: getMealPrep(product),
      },
    },
    editBakedHref: {
      pathname: '/account/edit-recipes/recipes/baked',
      query: { pet_id: pet.id, meal_prep: getMealPrep[product.product_type] },
    },
    editAddonsHref: {
      pathname: '/account/edit-recipes/extras',
      query: { pet_id: pet.id, direct_edit: true },
    },
    editDeliveryDateHref: {
      pathname: '/account/edit-delivery-date/quick',
      query: { pet_id: pet.id },
    },
    editDeliveryDateText: 'Change delivery date',
    latestOrderAddons: nextBoxAddons || [],
    lockInDate: format(parseISO(subscription.last_edit_datetime), 'MMM d'),
    isLockedIn: subscription.is_locked_in,
    trackingUrl: order?.tracking_url ?? '',
  };
};

export const getPlanType = product => {
  const hasDry = product.available_recipes.dry.length > 0;
  const hasFresh = product.available_recipes.fresh.length > 0;

  if (hasDry && hasFresh) {
    return 'mix';
  }
  if (hasDry) {
    return 'dry';
  }
  if (hasFresh) {
    return 'fresh';
  }
};

export const formatRecipeData = (recipes, productType, portionSize) => {
  const hasDry = recipes.dry?.length > 0;
  const hasFresh = recipes.fresh?.length > 0;
  let variable = '';

  if (hasDry && hasFresh) {
    variable = 'mix';

    return {
      variable: variable,
      recipes: {
        dry: capitalizeRecipeData(recipes.dry),
        fresh: capitalizeRecipeData(recipes.fresh),
      },
    };
  }
  if (hasDry) {
    variable = 'baked';

    return {
      variable: variable,
      recipes: {
        dry: capitalizeRecipeData(recipes.dry),
        fresh: [],
      },
    };
  }
  if (
    hasFresh &&
    productType === PRODUCT_TYPE_FRESH &&
    portionSize === HALF_PORTION
  ) {
    variable = PRODUCT_TYPE_HALF_FRESH;
    return {
      variable,
      recipes: {
        dry: [],
        fresh: capitalizeRecipeData(recipes.fresh),
      },
    };
  }
  if (hasFresh) {
    variable = 'fresh';

    return {
      variable: variable,
      recipes: {
        dry: [],
        fresh: capitalizeRecipeData(recipes.fresh),
      },
    };
  }
};

export const capitalizeRecipeData = recipes => {
  return recipes.map(recipe => capitalize(recipe));
};

export const formatMealPrepText = ({ product_type, portion_size }) => {
  if (product_type === 'FR' && portion_size === FULL_PORTION)
    return ['Full Fresh Plan'];
  if (product_type === 'FR' && portion_size === HALF_PORTION)
    return ['Half Fresh Plan'];
  if (product_type === 'DY') return ['All baked'];
  return ['A mixed bowl'];
};

export const formatPortionSectionText = product => {
  const freshRecipesList = product?.variants[0]?.recipes?.fresh || [];
  const dryRecipesList = product?.variants[0]?.recipes?.dry || [];

  const hasFreshRecipes = freshRecipesList.length > 0;
  const hasDryRecipes = dryRecipesList.length > 0;

  const packsPerDay =
    product && hasFreshRecipes && product?.portion_of_pack_per_day;
  const cupsPerDay = product && hasDryRecipes && product?.cups_per_day;
  const caloriesPerDay = product && parseFloat(product?.kcalories_per_day);

  const packsPerDayNum = parseFloat(packsPerDay);
  //checks if '/' is in the second position to determine if it's a fraction under 1 pack
  // Takes into account if value is 1+ cup(s) with no fractions.
  const isMoreThanOneCup =
    (cupsPerDay?.length === 1 && cupsPerDay !== '1') ||
    (cupsPerDay?.length > 1 && cupsPerDay?.split('')[1] !== '/');
  const cupText = isMoreThanOneCup ? 'cups' : 'cup';
  const packText = packsPerDay && packsPerDayNum > 1 ? 'packs' : 'pack';
  const cupsPerDayText = isMoreThanOneCup
    ? cupsPerDay?.replace(' ', ' & ')
    : cupsPerDay;
  const packsPerDayText =
    packsPerDayNum === 0.125
      ? '1/8'
      : packsPerDayNum === 0.25
      ? '1/4'
      : packsPerDayNum === 0.5
      ? '1/2'
      : packsPerDayNum;
  const freshText = `${packsPerDayText} ${packText} fresh`;
  const bakedText = `${cupsPerDayText} ${cupText} baked`;

  const portionText =
    packsPerDay && cupsPerDay
      ? `${freshText}, ${bakedText}/day`
      : packsPerDay
      ? `${freshText}/day`
      : cupsPerDay
      ? `${bakedText}/day`
      : '';

  return `${portionText} (${caloriesPerDay} calories)`;
};

export const getBoxManagementNavProps = ({
  nextDeliveryDate,
  subscription,
  address,
  payment,
  pet,
  product,
  currentOrder,
  subAddonProducts,
}) => {
  const weeks = subscription.frequency === 1 ? 'week' : 'weeks';
  const address2Added = address.address2 ? `, ${address.address2}` : '';
  const paymentInfo =
    payment && Object.values(payment)?.length && payment?.is_active ? (
      <StyledInfoLineWrapper>
        {
          PAYMENT_METHOD_IMAGES[
            payment?.wallet || payment?.brand?.toLowerCase()
          ]
        }{' '}
        {payment?.brand} ending in •••• {payment?.last_four_digits}
      </StyledInfoLineWrapper>
    ) : (
      <NoPaymentMethodText key="NoPaymentText">
        <ResponsiveImage
          src={warningIcon}
          alt={'No Payment Method added'}
          width={24}
          height={24}
        />
        No payment method added
      </NoPaymentMethodText>
    );

  const currentOrderAddonsSkus =
    currentOrder?.items
      .filter(item =>
        Object.values(ADDON_PRODUCT_TYPES).includes(item.product_type),
      )
      .map(addon => addon.sku) || [];

  //keep ongoing/future addons and those with a recurrence of 0 but are in the current box
  // addons with a recurrence of 0 which have exhausted thier uses.
  const boxManagementAddons = subAddonProducts?.filter(addon => {
    const ongoingOrFutureAddon =
      addon.recurrence === null || addon.recurrence > 0;
    //if the recurrence is 0, but it's in the current order we want to display it
    return ongoingOrFutureAddon || currentOrderAddonsSkus.includes(addon.sku);
  });

  return {
    label: interpolate('Manage {{pupName}}’s box', {
      pupName: pet?.name,
    }),
    shippingDate: [`${format(nextDeliveryDate, 'PP')}`],
    frequency: [
      subscription.frequency > 1
        ? `Every ${subscription.frequency} ${weeks}`
        : `Every week`,
    ],
    shippingInfo: [
      `${address.first_name} ${address.last_name}`,
      `${address.address1}${address2Added}`,
      `${address.city}, ${address.state} ${address.zip_code}`,
    ],
    paymentInfo,
    product: formatRecipeData(
      product.variants[0].recipes,
      product.product_type,
      product.portion_size,
    ),
    mealPrep: formatMealPrepText(product),
    // ^^ link component takes an array
    addons: boxManagementAddons || [],
    portionSectionText: [formatPortionSectionText(product)],
    isLockedIn: subscription?.is_locked_in,
  };
};

export const getYourPupProps = (props, pet, customer, order) => {
  return {
    ...interpolate(props, {
      pupName: pet.name,
    }),
    feedingGuidelinesHref: order
      ? `/feeding-guidelines/${customer.id}/${pet.id}/${order.packing_date}/feeding_guidelines_${pet.name}.pdf`
      : null,
    buttonUrlTarget: {
      pathname: props.buttonUrlTarget,
      query: { pet_id: pet.id },
    },
    reactivateUrlTarget: {
      pathname: props.reactivateUrlTarget,
      query: { pet_id: pet.id },
    },
    pupName: pet.name,
    pupBreed: getBreed(pet.breeds),
    pupInfo: getPetInfo(pet),
    pupBreedsNames: pet.breeds.map(breed => breed.name),
  };
};

/*
  There are questions surrounding the efficacy of the current order details
  modal. It thus may change and so may this.
*/
export const getOrderDetailsProps = ({
  subscription,
  pet,
  product,
  nextDeliveryDate,
}) => {
  const subscriptionAddon = getSubscriptionAddon(subscription);
  return {
    headline: 'Order Details',
    arrivalDate: format(nextDeliveryDate, 'MMMM d'),
    petName: pet.name,
    subscription: {
      frequency: subscription.frequency,
      price: `$${subscription.price}`,
    },
    addons: subscriptionAddon ? [subscriptionAddon] : [],
    editHref: '/account/edit-delivery-date',
    /*
      TODO: the following data currently isn't surfaced. More details are
      needed to get this to work correctly.
    */
    billingDate: 'June 24, 2021',
    subtotal: '$137.00',
    discountAmount: '$68.50',
    discountPercentage: '50%',
    taxes: '$3.00',
    total: '$71.50',
    planType: getPlanType(product),
  };
};

const getSubscriptionAddon = subscription => {
  if (subscription.addons?.length > 0) {
    let quantity = 0;
    let price = 0.0;
    for (const addon of subscription.addons) {
      quantity += addon.quantity;
      price += parseFloat(addon.price);
    }
    return {
      description: 'Subscription Extras:',
      quantity,
      price: `$${price.toFixed(2)}`,
    };
  }
};

export const getPetInfo = pet => {
  const age = pet.birth_date && getAge(pet.birth_date);
  const ageStr =
    pet.birth_date && pet.birth_date !== CRO_PET_BIRTH_DATE ? `${age}, ` : '';
  const allergies = getAllergies(pet.allergies);
  const neutered = getNeutered(pet.gender, pet.is_neutered);
  const activity = getActivity(pet.activity_level);
  return `${ageStr}${pet.weight} lbs, ${activity}, ${neutered}, and ${allergies}`;
};

export const getFilteredRecommendedAddons = (
  recommendedAddons,
  subscribedAddonSkus,
  treats,
  supplements,
) => {
  const unavailableAddons = getUnavailableAddons(treats, supplements);
  const recommendedDentalChew = getRecommendedDentalChew(treats);

  const getAddonDetails = (productType, sku) => {
    if (productType === 'TR') {
      return treats?.find(addon => addon.sku === sku);
    } else if (productType === 'SP') {
      return supplements?.find(addon => addon.sku === sku);
    }
  };

  return recommendedAddons
    .filter(addon => !unavailableAddons?.includes(addon.sku))
    .filter(
      addon =>
        addon?.sku === recommendedDentalChew?.sku ||
        !DENTAL_CHEWS_SKUS.includes(addon?.sku),
    )
    .filter(addon => !subscribedAddonSkus?.includes(addon?.sku))
    .map(addon => {
      const addonDetails = getAddonDetails(addon?.productType, addon?.sku);
      return {
        ...addon,
        price: addonDetails?.price,
        discountedPrice: addonDetails?.discounted_price,
        unit: getQuantityType(addonDetails?.product_type, addon?.sku),
      };
    });
};
