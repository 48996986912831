import { useMemo } from 'react';
import { isBefore, parseISO, format } from 'date-fns';
import { useCurrentPet } from '../useCurrentPet';
import { useSubscription } from '../../subscriptions';

export const useEditDeliveryProtectedRoute = () => {
  const { pet, isLoading: isPetLoading } = useCurrentPet();
  const {
    subscription,
    nextDeliveryDate,
    isLoading: isSubscriptionLoading,
  } = useSubscription(pet?.id);

  const { last_edit_datetime, is_locked_in } = subscription ?? {};

  const isAllowed = useMemo(
    () =>
      last_edit_datetime
        ? isBefore(new Date(), parseISO(last_edit_datetime)) && !is_locked_in
        : true,
    [last_edit_datetime, is_locked_in],
  );

  const nextDeliveryDateFormatted = useMemo(
    () => (nextDeliveryDate ? format(nextDeliveryDate, 'PPPP') : ''),
    [nextDeliveryDate],
  );

  return {
    isLoading: isPetLoading || isSubscriptionLoading,
    nextDeliveryDate: nextDeliveryDateFormatted,
    isAllowed,
  };
};
