import React from 'react';
import PropTypes from 'prop-types';

export const ProtectedRoute = ({ children, fallbackRender, isAllowed }) => {
  const isAllowedValue =
    typeof isAllowed === 'function' ? isAllowed() : isAllowed;

  if (!isAllowedValue) {
    return fallbackRender();
  }

  return <>{children}</>;
};

ProtectedRoute.displayName = 'ProtectedRoute';

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  fallbackRender: PropTypes.func.isRequired,
  isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
};
