import React, { useState } from 'react';
import Script from 'next/script';
import PropTypes from 'prop-types';
import { shared } from '../../config';
import { useToast, TOAST_CLIENT_ERROR_MESSAGE } from '../../Toast';
import {
  BrightbackCancelButton,
  CancelGFSubscriptionModal,
} from './components';

export const BrightbackContainer = ({
  customerId,
  customerEmail,
  petSubscriptionId,
  addOns,
  accountCreationDate,
  mealPrepType,
  planFrequency,
  lastOrderSubtotal,
  nextDeliveryDate,
  hasGFPlan,
}) => {
  const { showToast } = useToast();
  const [showModal, setShowModal] = useState(false);

  const handleBrightbackCancelClick = () => {
    const { Brightback } = window;
    const brightbackTab = window.open('', '_blank');
    if (Brightback) {
      Brightback.handleDataPromise({
        app_id: shared.BRIGHTBACK_APP_ID,
        subscription_id: petSubscriptionId,
        email: customerEmail,
        account: {
          internal_id: customerId,
          created_at: accountCreationDate,
        },
        custom: {
          subscription: {
            add_ons: addOns,
            meal_plan_type: mealPrepType,
            plan_frequency: planFrequency,
            last_order_subtotal: lastOrderSubtotal,
            next_delivery_date: nextDeliveryDate,
          },
        },
      }).then(result => {
        if (!!result.valid) {
          brightbackTab.location.assign(result.url);
        } else {
          showToast({
            status: 'error',
            message: TOAST_CLIENT_ERROR_MESSAGE,
            headline: 'Error Message',
          });
        }
      });
    }
  };

  return (
    <>
      <Script
        id="brightback-script"
        type="text/javascript"
        src="https://app.brightback.com/js/current/brightback.js?compiled=true"
        strategy="afterInteractive"
      />
      {hasGFPlan && (
        <CancelGFSubscriptionModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelClick={handleBrightbackCancelClick}
        />
      )}
      <BrightbackCancelButton
        handleCancelClick={
          hasGFPlan ? () => setShowModal(true) : handleBrightbackCancelClick
        }
        cancelButtonLabel={'Cancel Subscription'}
      />
    </>
  );
};

BrightbackContainer.displayName = 'BrightbackContainer';
BrightbackContainer.propTypes = {
  customerId: PropTypes.number.isRequired,
  customerEmail: PropTypes.string.isRequired,
  petSubscriptionId: PropTypes.string.isRequired,
  addOns: PropTypes.bool.isRequired,
  accountCreationDate: PropTypes.string.isRequired,
  mealPrepType: PropTypes.string.isRequired,
  planFrequency: PropTypes.number.isRequired,
  nextDeliveryDate: PropTypes.string.isRequired,
  lastOrderSubtotal: PropTypes.string.isRequired,
};
