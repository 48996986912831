import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import {
  Container,
  SubscriptionsContainer,
  SubscriptionContainer,
  SubscriptionInfo,
  IconContainer,
  Anchor,
  AnchorContainer,
  ActivityMarker,
} from './styles.js';

export const AccountSubscriptions = ({
  title,
  href,
  subscriptions,
  onSubscriptionsClick,
}) => (
  <Container onClick={onSubscriptionsClick}>
    <SubscriptionsContainer>
      <AnchorContainer>
        <Anchor href={href} onClick={onSubscriptionsClick}>
          {title}
        </Anchor>
      </AnchorContainer>
      {subscriptions.map(({ petName, status }) => (
        <SubscriptionContainer key={petName}>
          <SubscriptionInfo>{petName}</SubscriptionInfo>
          <SubscriptionInfo>
            <ActivityMarker $status={status} />
            {` Account ${status.toLowerCase()}`}
          </SubscriptionInfo>
        </SubscriptionContainer>
      ))}
    </SubscriptionsContainer>
    <IconContainer>
      <Icon
        iconName="chevron-right"
        alt="chevron right icon"
        color="text.secondary"
        size="22"
        id="account_subs_right"
      ></Icon>
    </IconContainer>
  </Container>
);

AccountSubscriptions.displayName = 'AccountSubscriptions';
AccountSubscriptions.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      petName: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSubscriptionsClick: PropTypes.func.isRequired,
};
