'use client';
import styled from 'styled-components';

export const StyledReferAFriendContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondary.chia};
  border-radius: 32px;
  width: 100%;
  text-align: center;
  padding: ${props => props.theme.spacing(8)};
  margin: 1rem auto 0 auto;

  @media ${({ theme }) => theme.mobileS} {
    width: 100%;
  }

  @media ${({ theme }) => theme.tabletL} {
    flex-direction: row;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media ${({ theme }) => theme.tabletL} {
    flex-direction: row;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: grid;
  width: 100%;
  max-width: none;
  gap: ${props => props.theme.spacing(4)};
  margin: ${props => props.theme.spacing(6, 0, 4)};
  text-align: center;

  @media ${({ theme }) => theme.tabletL} {
    max-width: 350px;
    margin-left: 2rem;
    text-align: left;
  }
`;

export const ReferHeading = styled.h3`
  ${({ theme }) => theme.typography.heading3};
  color: ${({ theme }) => theme.colors.text.lightest};
`;

export const ReferText = styled.p`
  ${({ theme }) => theme.typography.body};
  color: ${({ theme }) => theme.colors.text.lightest};
`;
