import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import warningIcon from '../../images/Icons/warning-icon.svg';
import { Modal } from '../Modal';
import {
  CancelButton,
  CancelGFSubscriptionContainer,
  PriceChangeHeading,
  GFCancellationText,
  ModalButtonsConainer,
} from './styles';
import { GF_CANCELLATION_TEXT } from './constants';

export const BrightbackCancelButton = ({
  handleCancelClick,
  cancelButtonLabel,
}) => (
  <CancelButton role="button" onClick={handleCancelClick}>
    {cancelButtonLabel}
  </CancelButton>
);

BrightbackCancelButton.displayName = 'BrightbackCancelButton';
BrightbackCancelButton.propTypes = {
  handleCancelClick: PropTypes.func.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
};

export const CancelGFSubscriptionModal = ({
  showModal,
  setShowModal,
  handleCancelClick,
}) => (
  <Modal
    shown={showModal}
    onCloseModal={() => setShowModal(false)}
    ariaLabel="Cancel Subscription price change warning Modal"
    closeBtnColor="border.fur"
    mobilePopUp={true}
    popupMobileDialoguePadding={6}
  >
    <CancelGFSubscriptionContainer>
      <ResponsiveImage
        src={warningIcon}
        alt={'Warning Icon'}
        width={40}
        height={40}
      />
      <PriceChangeHeading>Price change warning</PriceChangeHeading>
      <GFCancellationText>{GF_CANCELLATION_TEXT}</GFCancellationText>
      <ModalButtonsConainer>
        <Button
          variant={'cancel'}
          aria-label="Click to cancel plan and forfeit current pricing"
          onClick={handleCancelClick}
        >
          {' '}
          Proceed to cancellation
        </Button>
        <Button
          variant={'lightest'}
          aria-label="Click to keep current plan"
          onClick={() => setShowModal(false)}
        >
          Keep my current plan
        </Button>
      </ModalButtonsConainer>
    </CancelGFSubscriptionContainer>
  </Modal>
);

CancelGFSubscriptionModal.displayName = 'CancelGFSubscriptionModal';
CancelGFSubscriptionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
};
