'use client';
import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
  padding: ${props => props.theme.spacing(14, 6)};
  max-width: 565px;

  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(14, 0)};
  }
`;

export const HeadingContainer = styled.div`
  margin: ${props => props.theme.spacing(0, 0, 10, 0)};
`;

export const PaymentCardHeader = styled.div`
  ${props => props.theme.typography.heading4}
  color: ${props => props.theme.colors.text.primary};
  margin-bottom: ${props => props.theme.spacing(4)};
`;

export const PaymentCardHeaderWrapper = styled.div`
  text-align: left;
`;

export const StyledInfoLineWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: ${({ theme }) => theme.spacing(2)} !important;
  }
`;

export const StyledSection = styled.div`
  margin: 0 auto;

  max-width: 688px;
  padding-bottom: ${props => props.theme.spacing(10)};

  @media ${props => props.theme.tablet} {
    padding-bottom: ${props => props.theme.spacing(8)};
  }
`;
export const StyledInfo = styled.div`
  font-size: 1rem;

  max-width: 688px;
  padding-bottom: ${props => props.theme.spacing(10)};

  @media ${props => props.theme.tablet} {
    padding-bottom: ${props => props.theme.spacing(8)};
  }
`;

export const HrTag = styled.hr`
  border: none;
  height: 1px;
  background-color: ${({ theme }) =>
    theme.colors.withAlpha('border.fur', 0.25)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(4)};
  width: 100%;
  max-width: 512px;
  margin: ${props => props.theme.spacing(10)} auto;
`;

export const StyledNoPaymentMethodText = styled.p`
  font-size: 1rem;
  ${({ theme }) => theme.fontFamily.base}
  color: ${({ theme }) => theme.colors.status.warningPrimary};

  @media (max-width: 650px) {
    align-items: flex-start;
  }
`;
