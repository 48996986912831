export const updateBoxFrequencyEvent = (previous_frequency, new_frequency) => ({
  type: 'User updates box frequency',
  data: {
    previous_frequency,
    new_frequency,
  },
});

export const clickLinkEvent = () => ({
  type: 'User clicks change delivery date',
});
