'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { interpolate } from '@format/interpolate';
import { AddPupSuccess, Footer, LoadingView } from '@web/components';
import { usePet } from '../../pets';
import { useSubscription } from '../../subscriptions';
import { AccountHeader } from '../AccountHeader';
import { RedirectToLogin } from '../../router';
import { FOOTER_CONTENT } from '../../testing/constants';
import { useSessionCustomer } from '../../customer';
import { StyledAddPupSuccessContainer } from './styles.js';

export const AddPupSuccessContainer = ({
  pupSuccessInfo,
  urlTarget,
  links,
  logoUrl,
  logoSrText,
  petId,
}) => {
  const { pet, isLoading: petIsLoading } = usePet(petId);
  const {
    customer,
    isActive,
    isLoading: isCustomerLoading,
  } = useSessionCustomer();
  const { nextDeliveryDate, isLoading: subscriptionIsLoading } =
    useSubscription(petId);

  if (!isCustomerLoading && !isActive) {
    return <RedirectToLogin />;
  }
  if (petIsLoading || subscriptionIsLoading || isCustomerLoading) {
    return <LoadingView />;
  }

  const interpolatedPupSuccessInfo = interpolate(pupSuccessInfo, {
    pupName: pet.name,
  });

  return (
    <>
      <AccountHeader
        links={links}
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        name={customer.first_name}
      />
      <StyledAddPupSuccessContainer>
        <AddPupSuccess
          {...interpolatedPupSuccessInfo}
          date={format(nextDeliveryDate, 'PPPP')}
          urlTarget={urlTarget}
        />
      </StyledAddPupSuccessContainer>
      <Footer {...FOOTER_CONTENT} />
    </>
  );
};

AddPupSuccessContainer.displayName = 'AddPupSuccessContainer';

AddPupSuccessContainer.propTypes = {
  pupSuccessInfo: PropTypes.object.isRequired,
  urlTarget: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string.isRequired,
  logoSrText: PropTypes.string.isRequired,
  petId: PropTypes.string.isRequired,
};
