import React from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';

export const NarvarForm = ({ renderNarvarTracker, narvarScriptSrc }) => (
  <>
    <div data-narvar-feature="dynamicTrack" data-testid="narvar-root-div"></div>
    <Script
      data-testid="narvar-js"
      src={narvarScriptSrc}
      onLoad={() => renderNarvarTracker()}
    />
  </>
);

NarvarForm.displayName = 'NarvarForm';
NarvarForm.propTypes = {
  renderNarvarTracker: PropTypes.func.isRequired,
  narvarScriptSrc: PropTypes.string.isRequired,
};
