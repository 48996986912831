'use client';
import styled from 'styled-components';

export const StyledQuickSelectForm = styled.div`
  max-width: 660px;
  margin: auto;
  display: grid;
  gap: ${props => props.theme.spacing(8)};
  padding: ${props => props.theme.spacing(0, 6, 6)};

  @media ${props => props.theme.tablet} {
    gap: ${props => props.theme.spacing(6)};
  }
`;
export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${props => props.theme.spacing(4)};
`;

export const LinkContainer = styled.div`
  text-align: center;
  margin: ${props => props.theme.spacing(12, 0)};
`;
export const NextShippingContainer = styled.div`
  margin: ${props => props.theme.spacing(8, 0)};
`;
export const Subheading = styled.p`
  ${props =>
    props.subheading
      ? props.theme.typography.heading4
      : props.theme.typography.body}
  text-align: center;
  margin-bottom: ${props => props.theme.spacing(1)};
`;
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  gap: ${props => props.theme.spacing(4)};
  @media ${props => props.theme.tablet} {
    max-width: 512px;
  }
`;
