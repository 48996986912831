'use client';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { differenceInDays } from 'date-fns';
import { Button, LinkButton } from '@ui/button';
import { DatePicker, Cell } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules';
import warningInfoIcon from '../../images/Icons/warning-info-icon.svg';
import {
  ButtonContainer,
  NextShippingContainer,
  Subheading,
  StyledDatePickerForm,
  WarningText,
  BoxLockedInText,
  MaxDateReachedContainer,
  MaxDateReachedText,
} from './styles';

export const DatePickerForm = ({
  defaultValues,
  onSubmit,
  disabledDates,
  minDate,
  maxDate,
  labelButtonSave,
  labelButtonCancel,
  cancelButtonTarget,
  nextDeliveryLabel,
  nextDeliveryDate,
  isSubmitting,
  asapWarning,
  maxDateReachedWarning,
  boxLockedInWarning,
  orderIsLockedIn,
}) => {
  const [showWarning, setShowWarning] = useState(false);
  const [maxDateReached, setMaxDateReached] = useState(false);
  const { handleSubmit, control, watch } = useForm({
    defaultValues,
  });

  const selectedDeliveryTarget = watch(
    'next_delivery_target',
    defaultValues.next_delivery_target,
  );

  const currentDate = new Date();

  const dayDiff = differenceInDays(selectedDeliveryTarget, currentDate);

  useEffect(() => {
    if (dayDiff <= 3) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [dayDiff, setShowWarning]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledDatePickerForm>
        <NextShippingContainer>
          <Subheading>{nextDeliveryLabel}</Subheading>
          <Subheading subheading="true">{nextDeliveryDate}</Subheading>
          {orderIsLockedIn && (
            <BoxLockedInText>{boxLockedInWarning}</BoxLockedInText>
          )}
        </NextShippingContainer>
        <Cell position="center">
          <Controller
            control={control}
            name="next_delivery_target"
            render={({ field: { ref, value, ...field } }) => (
              <DatePicker
                {...field}
                disabledDates={disabledDates}
                minDate={minDate}
                maxDate={maxDate}
                selected={value}
                setMaxDateReached={setMaxDateReached}
              />
            )}
          />
        </Cell>
        {showWarning && (
          <Cell position="center">
            <WarningText>{asapWarning}</WarningText>
          </Cell>
        )}
        {maxDateReached && (
          <Cell position="center">
            <MaxDateReachedContainer>
              <ResponsiveImage
                alt="Warning info icon"
                src={warningInfoIcon}
                style={{ paddingTop: '4px' }}
                height={24}
                width={24}
              />
              <MaxDateReachedText>{maxDateReachedWarning}</MaxDateReachedText>
            </MaxDateReachedContainer>
          </Cell>
        )}
        <Cell>
          <ButtonContainer>
            <Button disabled={isSubmitting} type="submit">
              {labelButtonSave}
            </Button>
            <LinkButton variant="secondary" href={cancelButtonTarget}>
              {labelButtonCancel}
            </LinkButton>
          </ButtonContainer>
        </Cell>
      </StyledDatePickerForm>
    </form>
  );
};

DatePickerForm.displayName = 'DatePickerForm';

DatePickerForm.propTypes = {
  defaultValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabledDates: PropTypes.array.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  labelButtonSave: PropTypes.string.isRequired,
  labelButtonCancel: PropTypes.string.isRequired,
  cancelButtonTarget: PropTypes.string.isRequired,
  nextDeliveryLabel: PropTypes.string.isRequired,
  nextDeliveryDate: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  asapWarning: PropTypes.string.isRequired,
  maxDateReachedWarning: PropTypes.string.isRequired,
  boxLockedInWarning: PropTypes.string.isRequired,
  orderIsLockedIn: PropTypes.bool.isRequired,
};
