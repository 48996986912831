'use client';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { LOGGEDIN_LINKS } from '@web/molecules';
import { Heading } from '@web/atoms';
import { Footer, BoxSummaryExtras } from '@web/components';
import { ResponsiveImage } from '@web/molecules/ResponsiveImage';
import { InfoLink } from '../InfoLink';
import { RecipeNavLink } from '../RecipeNavLink';
import { AccountHeader } from '../AccountHeader';
import { FOOTER_CONTENT } from '../../testing/constants';
import { useReporter } from '../../reporter';
import { determinePlanType } from '../../products';
import warningIcon from '../../images/Icons/warning-icon.svg';
import {
  useExperiment,
  HIDE_BAKED_ONLY_MEMBER_EXPERIENCE,
} from '../../experiments';
import { MEAL_FREQUENCY_MAP } from '../constants';
import {
  onInfoSectionClicked,
  onPlanSummaryError,
  onPlanSummaryCancel,
} from './events';
import {
  Subtitle,
  StyledPlanSummaryExtras,
  InfoContainer,
  InfoSectionTitle,
  Container,
  ButtonContainer,
  BoxSummaryContainer,
  Disclaimer,
  UnavailableReactivationCart,
  NoPaymentMethodText,
  DiscountWarning,
} from './styles.js';

export const PlanSummaryExtras = ({
  headline,
  name,
  pupsInfo,
  recipesInfo,
  paymentInfo,
  deliveryInfo,
  subheadingText,
  confirmationText,
  cancelButtonLabel,
  boxheadline,
  offerCategory,
  totalDiscountAmount,
  totalAmount,
  reactivationCartAddonList,
  errorMessage,
  onSubmit,
  totalTax,
  mealPlanPriceInfo,
  onCancel,
  totalBeforeDiscount,
  reactivationMode,
  pupInfoTarget,
  mealPrepTarget,
  dryRecipesTarget,
  freshRecipesTarget,
  paymentTarget,
  deliveryTarget,
  cancelButtonTarget,
  isSubmitting,
  confirmationButtonText,
  portionSize,
  frequency,
  percentDiscount,
  availableReactivationCart,
  detailsSectionTitle,
  addonsTitle,
  addonsTarget,
  paymentMethodIsActive,
  offerCodeIsValid,
}) => {
  const reporter = useReporter();
  const onInfoTrack = section => () => {
    reporter.tag(onInfoSectionClicked(section, reactivationMode));
  };
  const { value: hideBaked } = useExperiment(
    HIDE_BAKED_ONLY_MEMBER_EXPERIENCE,
    false,
  );

  useEffect(() => {
    if (errorMessage) {
      reporter.tag(onPlanSummaryError(errorMessage));
    }
  }, [reporter, errorMessage]);

  const onCancelClick = () => {
    reporter.tag(onPlanSummaryCancel());
    onCancel?.();
  };

  const mealPrepType = determinePlanType(recipesInfo, portionSize);

  const bakedUnavailable = mealPrepType === 'baked' && hideBaked;

  const formatMealPrep = () => {
    if (mealPrepType === 'half_fresh') return 'Half Fresh Plan';
    if (mealPrepType === 'fresh') return 'Full Fresh Plan';
    return `A ${mealPrepType} bowl`;
  };

  const formatBoxSummaryMealPrep = () => {
    if (mealPrepType === 'half_fresh')
      return `${MEAL_FREQUENCY_MAP[frequency]}-week half fresh bowl plan`;
    if (mealPrepType === 'fresh')
      return `${MEAL_FREQUENCY_MAP[frequency]}-week full fresh bowl plan`;
    return `${MEAL_FREQUENCY_MAP[frequency]}-week full ${mealPrepType} bowl plan`;
  };

  return (
    <StyledPlanSummaryExtras>
      <AccountHeader
        links={LOGGEDIN_LINKS}
        logoUrl="/"
        logoSrText="Ollie logo"
        name={name}
      />
      <Container>
        <Heading
          headingLevel="h1"
          headingText={headline}
          typography="heading1"
          position="center"
        />
        {!offerCodeIsValid && (
          <DiscountWarning>
            ⓘ Reactivation discounts are subject to change and eligibility is
            based on usage.
          </DiscountWarning>
        )}
        <Subtitle>{subheadingText}</Subtitle>
        <InfoContainer>
          {availableReactivationCart && (
            <BoxSummaryContainer>
              <BoxSummaryExtras
                headline={boxheadline}
                recipes={recipesInfo}
                mealPrepText={formatBoxSummaryMealPrep()}
                totalBeforeDiscount={totalBeforeDiscount}
                totalTax={totalTax}
                addons={reactivationCartAddonList}
                mealPlanPriceInfo={mealPlanPriceInfo}
                totalAfterDiscount={totalAmount}
                totalDiscountAmount={totalDiscountAmount}
                offerCategory={offerCategory}
                discountPercentage={percentDiscount}
                paymentInfo={paymentMethodIsActive ? paymentInfo : []}
                button={{
                  loading: isSubmitting,
                  disabled:
                    isSubmitting ||
                    paymentInfo.length === 0 ||
                    bakedUnavailable,
                  ctaText: confirmationButtonText,
                  onClick: onSubmit,
                }}
                bakedUnavailable={bakedUnavailable}
                error={
                  errorMessage
                    ? {
                        message: errorMessage,
                        link: '/account/edit-payment',
                        linkText:
                          ' Please add a valid payment method to continue with your order',
                      }
                    : undefined
                }
                offerCodeIsValid={offerCodeIsValid}
              />
            </BoxSummaryContainer>
          )}
          {!availableReactivationCart && (
            <UnavailableReactivationCart>
              Please ensure that you have a valid payment method and address to
              view your reactivation details.
            </UnavailableReactivationCart>
          )}
          {availableReactivationCart && (
            <Disclaimer>{confirmationText}</Disclaimer>
          )}
          <InfoSectionTitle>{detailsSectionTitle}</InfoSectionTitle>
          <InfoLink
            title="Pup's info"
            href={pupInfoTarget}
            infoList={pupsInfo}
            cancelTarget={cancelButtonTarget}
            onInfoClick={onInfoTrack('Pup Info')}
          />
          <InfoLink
            title="Meal Plan"
            href={mealPrepTarget}
            infoList={[formatMealPrep()]}
            onInfoClick={onInfoTrack('Meal Plan')}
          />
          <RecipeNavLink
            title="Recipes"
            freshHref={freshRecipesTarget}
            bakedHref={dryRecipesTarget}
            recipes={recipesInfo}
            onInfoClick={onInfoTrack('Recipes')}
          />
          <InfoLink
            title={addonsTitle}
            href={addonsTarget}
            infoList={
              availableReactivationCart && reactivationCartAddonList.length > 0
                ? [
                    reactivationCartAddonList
                      .map(({ product_name }) => product_name)
                      .join(', '),
                  ]
                : ['None']
            }
            onInfoClick={onInfoTrack('Addons')}
          />
          <InfoLink
            title={'Payment method'}
            href={paymentTarget}
            infoList={[
              paymentInfo.length > 0 && paymentMethodIsActive ? (
                paymentInfo
              ) : (
                <NoPaymentMethodText>
                  <ResponsiveImage
                    src={warningIcon}
                    alt={'No Payment Method added'}
                    width={24}
                    height={24}
                  />
                  No payment method added
                </NoPaymentMethodText>
              ),
            ]}
            onInfoClick={onInfoTrack('Payment')}
          />
          <InfoLink
            title="Delivery info"
            href={deliveryTarget}
            infoList={deliveryInfo}
            onInfoClick={onInfoTrack('Delivery')}
          />
        </InfoContainer>
        <ButtonContainer>
          <LinkButton
            horizontalPadding={30}
            verticalPadding={3}
            variant="secondary"
            type="cancel"
            href={cancelButtonTarget}
            onClick={onCancelClick}
          >
            {cancelButtonLabel}
          </LinkButton>
        </ButtonContainer>
      </Container>
      <Footer {...FOOTER_CONTENT} />
    </StyledPlanSummaryExtras>
  );
};

PlanSummaryExtras.displayName = 'PlanSummaryExtras';

PlanSummaryExtras.propTypes = {
  name: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  subheadingText: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  pupsInfo: PropTypes.arrayOf(PropTypes.string).isRequired,
  recipesInfo: PropTypes.shape({
    fresh: PropTypes.arrayOf(PropTypes.string).isRequired,
    dry: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  paymentInfo: PropTypes.arrayOf(PropTypes.string).isRequired,
  deliveryInfo: PropTypes.arrayOf(PropTypes.string).isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  cancelButtonTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  boxheadline: PropTypes.string.isRequired,
  totalAmount: PropTypes.string.isRequired,
  mealPlanPriceInfo: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  totalDiscountAmount: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  reactivationCartAddonList: PropTypes.arrayOf(
    PropTypes.shape({
      product_name: PropTypes.string.isRequired,
      product_type: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      discounted_price: PropTypes.string.isRequired,
    }),
  ),
  percentDiscount: PropTypes.string.isRequired,
  frequency: PropTypes.number.isRequired,
  totalBeforeDiscount: PropTypes.string.isRequired,
  totalTax: PropTypes.string.isRequired,
  reactivationMode: PropTypes.bool,
  pupInfoTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  mealPrepTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  freshRecipesTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  dryRecipesTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  paymentTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  deliveryTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  offerCategory: PropTypes.string,
  confirmationButtonText: PropTypes.string,
  portionSize: PropTypes.string,
  availableReactivationCart: PropTypes.bool.isRequired,
  detailsSectionTitle: PropTypes.string.isRequired,
  addonsTitle: PropTypes.string.isRequired,
  addonsTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  paymentMethodIsActive: PropTypes.bool,
  offerCodeIsValid: PropTypes.bool,
};
