export const userClicksSeeAllExtras = () => ({
  type: 'Clicks view extras from quick-add',
});
export const userClicksAddExtra = product => ({
  type: 'Clicks add extra from quick-add',
  data: {
    product,
  },
});
export const userUpdatesExtra = (
  available_addons,
  previous_addons,
  new_addons,
) => ({
  type: 'User updates Extras',
  data: {
    available_addons,
    previous_addons,
    new_addons,
  },
});
