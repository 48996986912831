'use client';
import styled from 'styled-components';

export const Subheading = styled.p`
  ${props =>
    props.subheading
      ? props.theme.typography.body
      : props.theme.typography.heading5}
  text-align: center;
  margin: ${props => props.theme.spacing(3, 0)};
`;

export const WrapperHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${props => props.theme.spacing(12)};
  padding-bottom: ${props => props.theme.spacing(6)};
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background.base};
`;

export const Content = styled.div`
  max-width: 688px;
  margin: ${props => props.theme.spacing(0, 6)};
  @media ${props => props.theme.tablet} {
    margin: auto;
  }
`;
