import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { Heading } from '../Heading';
import { HeaderContainer } from './styles';
import { NavButton } from './NavButton';

export const Header = ({
  date,
  decreaseMonth,
  increaseMonth,
  nextMonthButtonDisabled,
  setMaxDateReached,
}) => {
  useEffect(() => {
    !!setMaxDateReached && setMaxDateReached(nextMonthButtonDisabled);
  }, [setMaxDateReached, nextMonthButtonDisabled]);

  return (
    <HeaderContainer>
      <NavButton type="back" onClick={decreaseMonth} />
      <Heading
        position="center"
        typography="heading2"
        headingLevel="h6"
        headingText={`${format(date, 'LLLL')} ${format(date, 'yyyy')}`}
      />
      <NavButton
        disabled={nextMonthButtonDisabled}
        type="forward"
        onClick={increaseMonth}
      />
    </HeaderContainer>
  );
};

Header.displayName = 'Header';
Header.propTypes = {
  date: PropTypes.object.isRequired,
  decreaseMonth: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
  nextMonthButtonDisabled: PropTypes.bool,
  setMaxDateReached: PropTypes.func,
};
