export const formatAddons = addons => {
  const addonsMap = {
    treats: [],
    supplements: [],
  };

  addons?.forEach(addon => {
    // just in case something goes wrong with useSubscriptionAddonProducts
    if (addon.product_type === 'TR') {
      addonsMap.treats.push(addon.product_name);
    } else if (addon.product_type === 'SP') {
      addonsMap.supplements.push(addon.product_name);
    }
  });

  return addonsMap;
};
