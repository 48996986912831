import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { IconButton } from './styles';

const TYPES = {
  back: {
    icon: 'chevron-left',
    name: 'Back',
  },
  forward: {
    icon: 'chevron-right',
    name: 'Forward',
  },
};

export const NavButton = ({ onClick, type, disabled = false }) => {
  const button = TYPES[type];
  return (
    <IconButton
      disabled={disabled}
      type="button"
      onClick={onClick}
      aria-label={button.name}
    >
      <Icon
        iconName={button.icon}
        size="25px"
        alt=""
        id="nav_button"
        color={disabled ? 'background.disabled' : undefined}
      />
    </IconButton>
  );
};
NavButton.displayName = 'NavButton';
NavButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['back', 'forward']).isRequired,
  disabled: PropTypes.bool,
};
