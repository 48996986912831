export const addDog = location => ({
  type: 'Click add dog',
  data: {
    location,
  },
});

export const viewDashboardPageEvent = () => ({
  type: 'View Dashboard',
});
