export const userSelectsDashboardMealPrep = () => ({
  type: 'Clicks meal prep',
});
export const userClicksOnFoodPortionSection = location => ({
  type: 'Clicks food portion section',
});
export const userClicksEditDeliveryDate = boxState => ({
  type: 'Clicks Delivery Date Section',
  data: {
    current_box_state: boxState ? 'locked' : 'unlocked',
  },
});
