import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import {
  Container,
  InfoListContainer,
  InfoLine,
  IconContainer,
  Anchor,
  AnchorContainer,
  Action,
} from './styles.js';

export const InfoLink = ({ title, href, infoList, onInfoClick }) => {
  return (
    <Container onClick={onInfoClick}>
      <InfoListContainer>
        <AnchorContainer>
          {href ? (
            <Anchor href={href} onClick={onInfoClick}>
              {title}
            </Anchor>
          ) : (
            <Action onClick={onInfoClick}>{title}</Action>
          )}
        </AnchorContainer>
        {infoList.map(info => (
          <InfoLine key={info}>{info}</InfoLine>
        ))}
      </InfoListContainer>
      <IconContainer>
        <Icon
          iconName="chevron-right"
          alt="chevron right icon"
          color="text.secondary"
          size="22"
          id="info_link"
        ></Icon>
      </IconContainer>
    </Container>
  );
};

InfoLink.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  infoList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onInfoClick: PropTypes.func,
};
