export const userClickEditFreshRecipe = () => ({
  type: 'Clicks edit fresh recipes',
});

export const userClickEditBakedRecipe = () => ({
  type: 'Clicks edit baked recipes',
});
export const userClickEditAddons = () => ({
  type: 'Clicks edit Extras from next box',
});
export const userClickChangeDeliveryDate = boxState => ({
  type: 'Clicks change delivery date',
  data: {
    current_box_state: boxState ? 'locked' : 'unlocked',
  },
});
export const userClicksPlusSignInNextBox = boxState => ({
  type: 'Clicks plus sign in next box',
});
