'use client';
import styled from 'styled-components';

export const CancelButton = styled.button`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.hero.cranberry};
  border: none;
  background-color: ${props => props.theme.colors.background.lightest};

  &:hover {
    text-decoration: underline;
  }
`;

export const CancelGFSubscriptionContainer = styled.div`
  width: 297px;
  height: 415px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media ${({ theme }) => theme.tablet} {
    width: 512px;
    height: 448px;
  }
`;
export const PriceChangeHeading = styled.h3`
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 121.429% */
  letter-spacing: 0.28px;
`;

export const GFCancellationText = styled.p`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0.16px;

  @media ${({ theme }) => theme.tablet} {
    width: 420px;
  }
`;

export const ModalButtonsConainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.tablet} {
    width: 297px;
    padding: ${props => props.theme.spacing(0, 2)};
  }
`;
