'use client';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.theme.colors.background.lightest};
  border-radius: ${props => props.theme.spacing(8)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spacing(6, 11)};
  margin-top: ${props => props.theme.spacing(2)};

  @media ${props => props.theme.tablet} {
    flex-direction: row;
    margin-top: ${props => props.theme.spacing(5)};
    padding: ${props => props.theme.spacing(4, 10, 4, 4)};
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  display: grid;
  gap: ${props => props.theme.spacing(2)};
  text-align: center;
  margin: ${props => props.theme.spacing(5, 0, 4, 0)};

  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(0, 9)};
    text-align: left;
  }
`;

export const Text = styled.span`
  ${props => props.theme.typography[props.typography]}
  color: ${props =>
    props.theme.colors.get(props.color, props.theme.colors.text.primary)};
`;

export const HeaderText = styled.h3`
  ${props => props.theme.typography.heading5}
  text-align: center;

  @media ${props => props.theme.tablet} {
    text-align: left;
  }
`;

export const ButtonContainer = styled.div`
  & > * :not(:first-child) {
    margin-top: ${props => props.theme.spacing(4)};
  }
`;
