'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { Dropdown, SmolLink } from '@web/atoms';
import { useReporter } from '../../reporter';
import { StyledDogSelection, Label, LinkContainer } from './styles.js';
import { onAddNewDog, dropdownClicked } from './events';

export const DogSelection = ({
  label,
  options,
  onChange,
  value,
  addPupLinkUrl,
  addPupLinkLabel,
}) => {
  const reporter = useReporter();
  const onAddAnotherPup = () => reporter.tag(onAddNewDog());
  const addPupLink = (
    <LinkContainer onClick={onAddAnotherPup}>
      <Icon
        iconName="plus"
        alt={addPupLinkLabel}
        size="16px"
        color="hero.blueberry"
        id="dog_selection"
      />
      <SmolLink href={addPupLinkUrl}>{addPupLinkLabel}</SmolLink>
    </LinkContainer>
  );

  return (
    <StyledDogSelection>
      <Label>{label}</Label>
      <Dropdown
        name="selected-dog"
        label="Select Dog"
        options={options}
        onChange={onChange}
        showLabel={false}
        selected={value}
        noSelectionLabel=""
        extraItems={addPupLink}
        onClick={() => reporter.tag(dropdownClicked())}
      />
    </StyledDogSelection>
  );
};
DogSelection.displayName = 'DogSelection';
DogSelection.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  addPupLinkUrl: PropTypes.string,
  addPupLinkLabel: PropTypes.string,
};
